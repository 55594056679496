import { StyleSheet } from "@react-pdf/renderer";
import temaPadrao from "_config/temas/estilo/base";

export const STYLES = StyleSheet.create({
  FONT_SIZE_08: {
    fontSize: 8,
  },
  FONT_SIZE_09: {
    fontSize: 9,
  },
  FONT_SIZE_10: {
    fontSize: 10,
  },
  FONT_SIZE_12: {
    fontSize: 12,
  },
  CONTAINER: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    marginBottom: 8,
  },
  TEXTO_CLARO: {
    color: temaPadrao.colors.neutral.neutral50,
  },
  TEXTO_QUASE_CLARO: {
    color: temaPadrao.colors.neutral.neutral60,
  },
  SECTION: {
    backgroundColor: temaPadrao.colors.neutral.neutral05,
    padding: 10,
    borderRadius: 8,
  },
  SECTION_ROW: {
    display: "flex",
    flexDirection: "row",
  },
  SECTION_COL_1: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    marginBottom: 8,
  },
  SECTION_COL_2: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    marginBottom: 8,
    paddingRight: 5
  },
  SECTION_COL_3: {
    width: "33%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    marginBottom: 8,
  },
  SECTION_COL_4: {
    width: "25%",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    marginBottom: 8,
  },
  NEGRITO: {
    fontWeight: "black",
    color: temaPadrao.colors.text,
  },
  DADOS_PENDENCIA: {
    backgroundColor: temaPadrao.colors.neutral.neutral10,
    borderRadius: 6,
    display: "flex",
    flexDirection: "row",
    marginBottom: 4,
    padding: 6,
  },
  SEM_MARGIN: {
    margin: 0,
  },
  BORDER_TOP_BOTTOM: {
    borderTop: `1px solid ${temaPadrao.colors.neutral.neutral20}`,
    borderBottom: `1px solid ${temaPadrao.colors.neutral.neutral20}`,
  },
  BACKGROUND_BRANCO: {
    backgroundColor: temaPadrao.colors.white,
  },
  PADDING_04: {
    padding: 4,
  },
  IMAGEM_CONTAINER: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
  },
  GAP_02: {
    gap: 2,
  },
});
