import { useCallback, useState } from "react";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import { IData, IHttpError } from "_services/api/interface/HttpResponse";
import { useRequest } from "hooks/useRequest";
import { IOptions } from "components/DropdownSelect";

export function useFonteDeConsulta() {
  const [optionsFonteDeConsulta, setOptionsFonteDeConsulta] = useState<
    IOptions[]
  >([]);
  const { get } = useRequest("consulta");

  const listarFontesDeConsulta = useCallback(async () => {
    try {
      const { status, data } = await get(`/consulta/fonte`);

      setOptionsFonteDeConsulta(
        data.map((fonte: any) => {
          return {
            value: fonte.sigla ?? fonte.FonteDeConsulta_sigla,
            label: fonte.nome ?? fonte.FonteDeConsulta_nome,
          };
        })
      );
      const response = new HttpResponse(false, status, data);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      setOptionsFonteDeConsulta([]);
      return response;
    }
  }, []);

  return {
    optionsFonteDeConsulta,
    listarFontesDeConsulta,
  };
}
