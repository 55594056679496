import { Route, Routes } from "react-router-dom";
import { DownloadArquivos } from "../pages/Download";
import { UploadArquivos } from "../pages/Upload";

export function ArquivosRoutes() {
  return (
    <Routes>
      <Route path="/download" element={<DownloadArquivos />} />
      <Route path="/upload" element={<UploadArquivos />} />
    </Routes>
  );
}