import { LoadingCirculo } from "components/LoadingCirculo";
import { ModalCustom } from "components/ModalCustom";
import { Container, ContainerDescricao, ContainerNumeros, ContainerTituloTabela, LoadingContainer } from "./style";
import { Informacao } from "components/Informacao";
import { formatarDinheiro } from "_utils/Money";

interface Props {
    show: boolean;
    loading: boolean;
    handleClose: () => void;
    onConfirm: () => void;
    qtdLinhas: number;
    valorConsulta: number;
}

export function ModalValorConsultarDebitos({
    handleClose,
    onConfirm,
    show,
    loading,
    qtdLinhas,
    valorConsulta
}: Props) {

    const mensagem = "Confira abaixo a quantidade de placas e uma estimativa do valor que será cobrado.";

    return (
        <ModalCustom
            show={show}
            title="Consultar Débitos"
            handleClose={handleClose}
            centered
            onConfirm={onConfirm}
            footer
            footerConfirmButton
        >
            {loading ? (
                <LoadingContainer>
                    <LoadingCirculo mostrar={loading} />
                </LoadingContainer>
            ) : (
                <div>
                    <ContainerDescricao>Deseja confirmar a consulta das placas solicitadas? Esta ação irá gerar um recibo de cobrança.</ContainerDescricao>
                    <Informacao
                        type="atencao"
                        mensagem={mensagem}
                        showBotaoFechar={false}
                        showLabel={false}
                        style={{ boxShadow: "none", fontSize: "14px", marginBottom: "24px" }}
                    />
                    <Container>
                        <ContainerTituloTabela><strong>Qtd de placas</strong></ContainerTituloTabela>
                        <ContainerTituloTabela><strong>Valor</strong></ContainerTituloTabela>
                    </Container>
                    <hr style={{ margin: "0" }}></hr>
                    <Container>
                        <ContainerNumeros>{qtdLinhas}</ContainerNumeros>
                        <ContainerNumeros>{formatarDinheiro(valorConsulta)}</ContainerNumeros>
                    </Container>
                </div>
            )}
        </ModalCustom>
    );
}
