import { useCallback, useState } from "react";
import api from "_services/api";
import { HttpError } from "_services/api/HttpError";
import { HttpResponse } from "_services/api/HttpResponse";
import {
  IData,
  IHttpError,
  IHttpResponse,
} from "_services/api/interface/HttpResponse";
import { formataData } from "_utils/Data";
import { IFiltrosPagamentos } from "../containers/Filtos/interfaces/IFiltrosPagamentos";
import { StatusDebitoPagamento } from "../Enum/StatusDebitoPagamento";
import { IDetalhesPagamentos } from "../interfaces/IDetalhesPagamentos";
import { HttpStatusCode } from "axios";
import { useRequest } from "hooks/useRequest";

export function usePagamento() {
  const [loading, setLoading] = useState(false);
  const [loadingBuscarTodos, setLoadingBuscarTodos] = useState(false);
  const [loadingDadosPlanilha, setLoadingDadosPlanilha] = useState(false);
  const [idsDebitos, setIdsDebitos] = useState<number[]>([]);
  const [filtroPagamento, setFiltroPagamento] = useState<IFiltrosPagamentos>({} as IFiltrosPagamentos);
  const [error, setError] = useState<IHttpResponse<IData | any>>();
  const [motivoRecusa, setMotivoRecusa] = useState<string>();
  const [dados, setDados] = useState<IDetalhesPagamentos[]>([]);
  const [todosDebitos, setTodosDebitos] = useState<any[]>([]);
  const [valor, setValor] = useState<number>(0);
  const [desconto, setDesconto] = useState<number>(0);
  const [juros, setJuros] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [multa, setMulta] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(100);
  const [totalRows, setTotalRows] = useState<number>(0);

  const { post, get, patch } = useRequest("consulta")

  function handlePageChange(page: number) {
    setPage(page);
  }

  function handleLimitChange(limit: number) {
    setLimit(limit);
  }

  function calcularValorDebitos(debitos: any[]) {
    const total = debitos.reduce((soma, atual) => {
      if (atual.valorUltimaConsulta) {
        return soma + atual.valorUltimaConsulta;
      }

      return soma + atual.valor;
    }, 0);
    const juros = debitos.reduce((soma, atual) => soma + atual.juros, 0);
    const valor = debitos.reduce((soma, atual) => soma + atual.valor, 0);
    setTotal(total);
    setJuros(juros);
    setValor(valor);
  }

  async function buscarValorTodosDebitos(id: any) {
    setLoading(true);
    try {
      const { status, data } = await api.get(
        `debito/pagamento/solicita/${id}/debitos?${filtroPagamento}`
      );
      const response = new HttpResponse(false, status, data);
      setError(response);
      setTodosDebitos(data.items);
      const idsTodosDebitos = data.items.map((item: any) => item.id);
      setIdsDebitos(idsTodosDebitos);
      calcularValorDebitos(dados);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      return response;
    } finally {
      setLoading(false);
    }
  }

  const buscarDadosPlanilha = useCallback(async (idSolicitacaoPagamento: string | number) => {
    setLoadingDadosPlanilha(true);
    const ajuste = [
      filtroPagamento?.prazoPagamento
        ? `prazoPagamento=${formataData(
          filtroPagamento.prazoPagamento.toISOString()
        )}`
        : null,
      filtroPagamento?.idTipoDebito
        ? `tipoDebito=${filtroPagamento.idTipoDebito}`
        : null,
      filtroPagamento?.statusPrazoPagamento
        ? `statusPrazoPagamento=${filtroPagamento.statusPrazoPagamento}`
        : null,
      filtroPagamento?.statusPagamento?.length
        ? `status=${filtroPagamento.statusPagamento}`
        : null,
      filtroPagamento?.placa ? `placa=${filtroPagamento.placa}` : null,
      filtroPagamento?.renavam ? `renavam=${filtroPagamento.renavam}` : null,
      filtroPagamento?.fonteDeConsulta ? `fonteDeConsulta=${filtroPagamento.fonteDeConsulta}` : null,
    ];

    const queryParam = ajuste.filter((item) => item !== null).join("&");
    try {
      const { status, data } = await get(
        `pagamento/solicita/${idSolicitacaoPagamento}/debitos?${queryParam}&paginate=0`
      );

      const response = new HttpResponse(false, status, data);
      setError(response);
      return response;
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      if (error.status !== HttpStatusCode.NotFound) {
        setError(response);
      }
      return response;
    } finally {
      setLoadingDadosPlanilha(false);
    }
  }, [filtroPagamento]);

  const buscarDetalhes = useCallback(
    async (idConsulta: any) => {
      const ajuste = [
        filtroPagamento?.prazoPagamento
          ? `prazoPagamento=${formataData(
            filtroPagamento.prazoPagamento.toISOString()
          )}`
          : null,
        filtroPagamento?.idTipoDebito
          ? `tipoDebito=${filtroPagamento.idTipoDebito}`
          : null,
        filtroPagamento?.statusPrazoPagamento
          ? `statusPrazoPagamento=${filtroPagamento.statusPrazoPagamento}`
          : null,
        filtroPagamento?.statusPagamento
          ? `status=${filtroPagamento.statusPagamento.join(",")}`
          : null,
        filtroPagamento?.placa ? `placa=${filtroPagamento.placa}` : null,
        filtroPagamento?.renavam ? `renavam=${filtroPagamento.renavam}` : null,
        filtroPagamento?.fonteDeConsulta ? `fonteDeConsulta=${filtroPagamento.fonteDeConsulta}` : null,
      ];

      const queryParam = ajuste.filter((item) => item !== null).join("&");

      const selecionaveis = ["Indeferido", "Erro", "Pendente"];
      setLoading(true);
      try {
        const { status, data } = await get(
          `/pagamento/solicita/${idConsulta}/debitos?${queryParam}&page=${page}&limit=${limit}`
        );
        data.items.forEach((item: any) => {
          if (selecionaveis.includes(StatusDebitoPagamento[item.status])) {
            item.selectable = true;
            return;
          }
          item.selectable = false;
        });

        setDados(data.items);
        setTotalRows(data.meta.totalItems);
        const response = new HttpResponse(false, status, data);
        setError(response);
        return response;
      } catch (error: IHttpError<IData, any> | any) {
        const response = new HttpError<IData>(true, error);
        if (error.status !== HttpStatusCode.NotFound) {
          setError(response);
        }
        setDados([]);
        return response;
      } finally {
        setLoading(false);
      }
    },
    [filtroPagamento, limit, page]
  );

  const buscarTodosDebitos = useCallback(
    async (idSolicitacaoPagamento: number | string) => {
      const ajuste = [
        filtroPagamento?.prazoPagamento
          ? `prazoPagamento=${formataData(
            filtroPagamento.prazoPagamento.toISOString()
          )}`
          : null,
        filtroPagamento?.idTipoDebito
          ? `tipoDebito=${filtroPagamento.idTipoDebito}`
          : null,
        filtroPagamento?.statusPrazoPagamento
          ? `statusPrazoPagamento=${filtroPagamento.statusPrazoPagamento}`
          : null,
        filtroPagamento?.statusPagamento?.length
          ? `status=${filtroPagamento.statusPagamento}`
          : null,
      ];

      const queryParam = ajuste.filter((item) => item !== null).join("&");

      setLoadingBuscarTodos(true);
      const selecionaveis = ["Indeferido", "Erro", "Pendente"];
      try {
        const { status, data } = await get(
          `pagamento/solicita/${idSolicitacaoPagamento}/debitos?${queryParam}&paginate=0`
        );

        const disponiveis = data
          .map((item: any) => {
            if (selecionaveis.includes(StatusDebitoPagamento[item.status])) {
              return item;
            }

            return null;
          })
          .filter((item: any) => item !== null);

        setIdsDebitos(disponiveis.map((item: any) => item.debitoPagamentoId));
        calcularValorDebitos(disponiveis);
        setTodosDebitos(disponiveis);
        const response = new HttpResponse(false, status, disponiveis);
        setError(response);
        return response;
      } catch (error: IHttpError<IData, any> | any) {
        const response = new HttpError<IData>(true, error);
        if (error.status !== HttpStatusCode.NotFound) {
          setError(response);
        }
        return response;
      } finally {
        setLoadingBuscarTodos(false);
      }
    },
    [filtroPagamento]
  );

  async function pagarDebitos() {
    setLoading(true);
    setLoadingBuscarTodos(true);
    try {
      return await post<{ idsDebitosPagamento: number[] }, any>(`pagamento/solicita/realiza`, {
        idsDebitosPagamento: idsDebitos,
      });
    } catch (error: IHttpError<IData, any> | any) {
      const response = new HttpError<IData>(true, error);
      return response;
    } finally {
      setLoading(false);
      setLoadingBuscarTodos(false);
    }
  }

  async function recusarDebitos() {
    return patch(`pagamento/debito/recusa/lote`, {
      idsDebitosPagamento: idsDebitos,
      mensagem: motivoRecusa,
    });
  }

  return {
    pagarDebitos,
    error,
    recusarDebitos,
    setIdsDebitos,
    setFiltroPagamento,
    setMotivoRecusa,
    buscarValorTodosDebitos,
    motivoRecusa,
    buscarDetalhes,
    dados,
    filtroPagamento,
    loading,
    buscarTodosDebitos,
    todosDebitos,
    valor,
    setValor,
    desconto,
    setDesconto,
    juros,
    setJuros,
    total,
    setTotal,
    handlePageChange,
    handleLimitChange,
    page,
    limit,
    loadingBuscarTodos,
    calcularValorDebitos,
    buscarDadosPlanilha,
    loadingDadosPlanilha,
    totalRows,
  };
}


