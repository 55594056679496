import { yupResolver } from "@hookform/resolvers/yup";
import { CompositeContainer } from "components/CompositeContainer";
import { DropdownSelect } from "components/DropdownSelect";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";


interface Props {
    setRenomearPara: (renomear: string) => void;
    setTipoArquivo: (tipoArquivo: string) => void;
}

export interface IFiltrosUploadArquivo {
    tipoDeArquivo: string,
    valorParaRenomear: string;
}

export const filtrarDetalhesEmissaoSchema = yup.object().shape({
    tipoDeArquivo: yup.string().optional().nullable(),
    valorParaRenomear: yup.string().optional().nullable(),
});


export function ConfiguracoesArquivos({
    setRenomearPara,
    setTipoArquivo,
}: Props) {
    const {
        control,
    } = useForm<IFiltrosUploadArquivo>({
        resolver: yupResolver(filtrarDetalhesEmissaoSchema),
    });

    return (
        <CompositeContainer.Root>
            <CompositeContainer.Body>
                <Form noValidate>
                    <Row>
                        <Col lg="4">
                            <Form.Group>
                                <Controller
                                    control={control}
                                    name="tipoDeArquivo"
                                    render={({ field: { onChange, value } }) => (
                                        <DropdownSelect
                                            options={[
                                                { label: 'Licenciamento', value: 1 },
                                            ]}
                                            title="Tipo de Arquivo"
                                            selectedValue={value}
                                            onSelect={(event) => {
                                                onChange(event);
                                                setTipoArquivo(event);
                                            }}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>
                        <Col lg="4">
                            <Form.Group>
                                <Controller
                                    control={control}
                                    name="valorParaRenomear"
                                    render={({ field: { onChange, value } }) => (
                                        <DropdownSelect
                                            options={[
                                                { label: 'Placa_ano', value: 'Placa_ano' },
                                                { label: 'Placa_ano_crlv', value: 'Placa_ano_CRLV' },
                                            ]}
                                            title="Renomear para"
                                            selectedValue={value}
                                            onSelect={(event) => {
                                                setRenomearPara(event);
                                                onChange(event);
                                            }}
                                        />
                                    )}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </CompositeContainer.Body>
        </CompositeContainer.Root>
    )
}