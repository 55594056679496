import { Stronger } from "components/Stronger";
import { IconeNadaConsta } from "../IconeNadaConsta";
import { IconePendencia } from "../IconePendencia";
import { Section } from "../Section";
import { useEffect, useState } from "react";
import { converter8DigitosParaData } from "_utils/Data";

export interface IDadosRecall {
  status: string;
  identificador: string;
  dataUltimaAtualizacao: string;
  dataInicio: string;
  dataLimite: string;
  nome: string;
  descricaoDefeito: string;
}

interface Props {
  recalls: IDadosRecall[] | null;
}

export function DadosRecall({ recalls }: Readonly<Props>) {
  const [recallsFiltrados, setRecallsFiltrados] = useState<IDadosRecall[]>([]);
  useEffect(() => {
    setRecallsFiltrados(recalls?.filter((recall) => recall.status === "Sucesso") ?? []);
  }, [recalls]);
  return (
    <Section.Root>
      <Section.Header>
        <Section.Icone icone={recallsFiltrados?.length ? IconePendencia : IconeNadaConsta} />
        <Section.Titulo titulo="Recall" />
      </Section.Header>
      <Section.Body>
        {recallsFiltrados?.length ? (
          recallsFiltrados?.map((recall) => (
            <>
              <Section.Row>
                <Section.Col>
                  <span>Identificador</span> <br />
                  <Stronger>{recall.identificador}{recall.nome ? ` - ${recall.nome.replace(`${recall.identificador}-`, "")}` : ""}</Stronger>
                </Section.Col>
                <Section.Col>
                  <span>Data de registro</span> <br />
                  <Stronger>{converter8DigitosParaData(recall.dataInicio)}</Stronger>
                </Section.Col>
              </Section.Row>
              <Section.Row>
                <Section.Col>
                  <span>Descrição do defeito</span> <br />
                  <Stronger>{recall.descricaoDefeito}</Stronger>
                </Section.Col>
              </Section.Row>
            </>
          ))
        ) : (
          <Section.Row>
            <Section.Col>
              <span>Recall</span> <br />
              <Stronger>Nada consta</Stronger>
            </Section.Col>
          </Section.Row>
        )}
      </Section.Body>
    </Section.Root>
  );
}

