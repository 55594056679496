import {
  ButtonToggleVisualizacao,
  Container,
  ExportExcelContainer,
  LoadingSpinner,
} from "./style";
import { ElementType, ReactNode } from "react";

interface IExportExcel {
  onClick?: () => void;
  variant?: "primary" | "secondary";
  icone: ElementType;
  texto: string;
  showToggleVisualizacao?: boolean;
  onClickToggleVisualizacao?: () => void;
  toggleComponent?: ReactNode;
  isLoading?: boolean;
}

function ExportExcel({
  onClick,
  variant = "primary",
  icone: Icone,
  texto,
  onClickToggleVisualizacao = () => {},
  showToggleVisualizacao,
  toggleComponent,
  isLoading = false,
}: Readonly<IExportExcel>) {
  return (
    <Container>
      {showToggleVisualizacao && (
        <ButtonToggleVisualizacao
          onClick={onClickToggleVisualizacao}
          type="button"
        >
          {toggleComponent}
        </ButtonToggleVisualizacao>
      )}
      {onClick && (
        <ExportExcelContainer
          variant={variant}
          onClick={!isLoading ? onClick : undefined}
          disabled={isLoading}
        >
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              {texto}
            </>
          )}
          <Icone />
        </ExportExcelContainer>
      )}
    </Container>
  );
}
export { ExportExcel };
