import { ReactElement } from "react";
import { TooltipContainer } from "./style";

interface IInfoTooltipProps {
    mensagem: string | ReactElement;
    transformeX?: number;
}
export function InfoTooltip({ mensagem, transformeX = 60 }: IInfoTooltipProps) {
    return (
        <TooltipContainer transformX={transformeX}>
            {mensagem}
        </TooltipContainer>
    )
}