import { toastErro } from "components/Toast";
import { Page } from "containers/Page";
import { useExpandableRow } from "hooks/useExpandableRow";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { verificarPermissao } from "_services/auth";
import { Filtros } from "./containers/Filtos";
import { IFiltrosPagamentos } from "./containers/Filtos/interfaces/IFiltrosPagamentos";
import { ModalConfirmacao } from "./containers/ModalConfirmacao";
import { ModalConfirmacaoRecusar } from "./containers/ModalConfirmacaoRecusar";
import { TablePagamentos } from "./containers/TablePagamentos";
import { useLinha } from "./hook/useLinha";
import { usePagamento } from "./hook/usePagamento";
import { ModalPagamentoManual } from "./containers/ModalPagamentoManual";
import { ModalConfirmacaoPagamentoManual } from "./containers/ModalConfirmacaoPagamentoManual";
import { ModalConfirmacaoPagamentoSelecionados } from "./containers/ModalConfirmacaoPagamentoSelecionados";
import { useFonteDeConsulta } from "./hook/useFonteDeConsulta";

export function DetalhesPagamento() {
  const { id } = useParams();
  const { setRowExpandToggled, rowToExpand } = useExpandableRow();
  const [show, setShow] = useState<boolean>(false);
  const [todos, setTodos] = useState<boolean>(false);
  const [idSolicitacao, setIdSolicitacao] = useState<number>(0);
  const [showConfirmacao, setShowConfirmacao] = useState<boolean>(false);
  const [showRecusar, setShowModalRecusar] = useState<boolean>(false);
  const [idSolicitacaoRecusar, setIdSolicitacaoRecusar] = useState<number>(0);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [disableManual, setDisableManual] = useState<boolean>(true);
  const [showConfirmacaoRecusar, setShowConfirmacaoRecusar] = useState<boolean>(false);
  const [showPagamentoManual, setShowPagamentoManual] = useState<boolean>(false);
  const [showConfirmarPagamentoManual, setShowConfirmarPagamentoManual] = useState<boolean>(false);
  const [showConfirmacaoPagamentoSelecionados, setShowConfirmacaoPagamentoSelecionados] = useState<boolean>(false);
  const [clearRows, setClearRows] = useState<boolean>(false);

  const {
    pagarDebitos,
    recusarDebitos,
    setIdsDebitos,
    setMotivoRecusa,
    motivoRecusa,
    buscarDetalhes,
    loading,
    filtroPagamento,
    setFiltroPagamento,
    buscarTodosDebitos,
    todosDebitos,
    setJuros,
    setDesconto,
    setTotal,
    setValor,
    valor,
    desconto,
    juros,
    dados,
    handlePageChange,
    handleLimitChange,
    page,
    loadingBuscarTodos,
    limit,
    total,
    calcularValorDebitos,
    totalRows,
    buscarDadosPlanilha,
    loadingDadosPlanilha,
  } = usePagamento();
  const { setSelectedRows, selectedRows, limparSelectedRows } = useLinha();
  const navigate = useNavigate();

  const { listarFontesDeConsulta, optionsFonteDeConsulta } = useFonteDeConsulta();

  useEffect(() => {
    setDisableManual(true);

    if (selectedRows.length === 1) {
      setDisableManual(false);
    }
  }, [selectedRows]);

  useEffect(() => {
    const permissaoFinanceiro = verificarPermissao("pagamento");
    if (!permissaoFinanceiro) {
      navigate("/401");
    }
    listarFontesDeConsulta();
  }, []);

  useEffect(() => {
    buscarDetalhes(id);
  }, [filtroPagamento, page, limit, buscarDetalhes, id]);

  async function buscarDebitos() {
    const { hasErro, data } = await buscarTodosDebitos(id!);

    if (hasErro || data.length <= 0) {
      toastErro(data.message || "Não existem débitos disponíveis para recusa");
      handleCloseModalRecusarPagamento();
      handleClose();
      return;
    }
  }

  const getFiltro = (filtros: IFiltrosPagamentos) => {
    setFiltroPagamento(filtros);
  };

  const getMotivoRecusa = (motivoRecusa: string) => {
    setMotivoRecusa(motivoRecusa);
  };

  const handleCloseModalConfirmacao = () => {
    handleClose();
    setShowConfirmacao(false);
  };

  const handleCloseModalConfirmacaoRecusar = () => {
    handleClose();
    setShowConfirmacaoRecusar(false);
  };

  const handleClose = () => {
    if (todos) {
      limparSelectedRows();
      setTodos(false);
    }
    setShow(false);
    setValor(0);
    setJuros(0);
    setDesconto(0);
    setTotal(0);
    handleClearRows();
  };
  const handleShow = () => setShow(true);

  const getLinhas = (linhas: any[]) => {
    setSelectedRows(linhas);
    setIdsDebitos(linhas.map((item) => item.debitoPagamentoId));
  };

  const showPagarTodos = async () => {
    limparSelectedRows();
    setTodos(true);

    handleShow();
  };

  const showPagarSelecionados = () => {
    setShowConfirmacaoPagamentoSelecionados(false);
    setTodos(false);
    calcularValorDebitos(selectedRows);
    handleShow();
  };

  const realizarPagamento = async () => {
    const { data, hasErro } = await pagarDebitos();

    if (hasErro) {
      toastErro(data.message);
      handleClose();
      return;
    }

    setIdSolicitacao(data.id_solicitacao);
    setShowConfirmacao(true);
    buscarDetalhes(id);
    handleClose();
  };

  const recusarPagamentos = async () => {
    if (!motivoRecusa) {
      setShowFeedback(true);
      return;
    }

    const { data, hasErro } = await recusarDebitos();

    if (hasErro) {
      toastErro(data.message);
      handleClose();
      return;
    }

    setIdSolicitacaoRecusar(data.id_solicitacao);
    setShowConfirmacaoRecusar(true);

    handleCloseModalRecusarPagamento();
    buscarDetalhes(id);
  };

  const handleShowModalRecusarPagamento = () => {
    setShowModalRecusar(true);
  };

  const handleCloseModalRecusarPagamento = () => {
    setShowModalRecusar(false);
    setShowFeedback(false);
    handleClose();
  };

  useEffect(() => {
    setTodos(true);
    if (selectedRows.length > 0) {
      setTodos(false);
    }
  }, [selectedRows]);

  function handleClearRows() {
    setClearRows(false);
    setClearRows(true);
  }

  return (
    <Page title="Pagamento de Débitos">
      <Filtros getFiltro={getFiltro} optionsFonteDeConsulta={optionsFonteDeConsulta} />
      <TablePagamentos
        loading={loading}
        dados={dados}
        idSolicitacao={id!}
        rowToExpand={rowToExpand}
        setRowExpandToggled={setRowExpandToggled}
        handleClose={handleClose}
        todos={todos}
        show={show}
        showPagarTodos={showPagarTodos}
        showPagarSelecionados={() => setShowConfirmacaoPagamentoSelecionados(true)}
        realizarPagamento={realizarPagamento}
        setSelectedRows={getLinhas}
        recusarPagamentos={recusarPagamentos}
        handleShowModalRecusarPagamento={handleShowModalRecusarPagamento}
        handleCloseModalRecusarPagamento={handleCloseModalRecusarPagamento}
        showRecusar={showRecusar}
        showFeedback={showFeedback}
        getMotivoRecusa={getMotivoRecusa}
        selectedRows={selectedRows}
        todosDebitos={todosDebitos}
        valor={valor}
        total={total}
        desconto={desconto}
        acrecimos={juros}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        handleBuscarDebitos={buscarDebitos}
        loadingBuscarTodos={loadingBuscarTodos}
        clearSelectedRows={clearRows}
        rowsPerPage={limit}
        totalRows={totalRows}
        setShowPagamentoManual={setShowPagamentoManual}
        disableManual={disableManual}
        buscarDadosPlanilha={buscarDadosPlanilha}
        loadingDadosPlanilha={loadingDadosPlanilha}
        optionsFonteDeConsulta={optionsFonteDeConsulta}
      />
      <ModalConfirmacao
        idSolicitacao={idSolicitacao}
        handleClose={handleCloseModalConfirmacao}
        show={showConfirmacao}
      />
      <ModalConfirmacaoPagamentoSelecionados
        onConfirm={showPagarSelecionados}
        handleClose={() => setShowConfirmacaoPagamentoSelecionados(false)}
        show={showConfirmacaoPagamentoSelecionados}
      />
      <ModalPagamentoManual
        show={showPagamentoManual}
        setShowPagamentoManual={setShowPagamentoManual}
        selectedRows={selectedRows}
        openShowConfirmarPagamentoManual={() => setShowConfirmarPagamentoManual(true)}
        dados={dados}
        handleClearRows={handleClearRows}
      />
      <ModalConfirmacaoRecusar
        idSolicitacaoRecusar={idSolicitacaoRecusar}
        handleClose={handleCloseModalConfirmacaoRecusar}
        show={showConfirmacaoRecusar}
      />
      <ModalConfirmacaoPagamentoManual
        show={showConfirmarPagamentoManual}
        handleClose={() => setShowConfirmarPagamentoManual(false)}
      />
    </Page>
  );
}
