import styled from "styled-components";

export const ContainerUpload = styled.div`
    height: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ContainerUploadDragAndDrop = styled.div`
    border: 2px dashed #B3B3B3;
    width: 90%;
    height: 300px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    flex-direction: column;
    transition: ease 0.5s;
    margin-bottom: 20px;
`;

export const ContainerUploadIcone = styled.div`
    background-color: #E7F5EB;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-bottom: 20px;
`;

export const ContainerArquivosUpados = styled.div`
    min-width: 90%;
    display: flex;
    flex-wrap: wrap;
`;

export const ArquivoUpado = styled.div`
    flex: 1 1 30%;
    height: 60px;
    background-color: #FAFAFA;
    box-sizing: border-box;
    margin: 5px;
    display: flex;
`;

export const HeaderArquivoUpado = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: calc(100% - 60px);
    padding: 8px;
`;

export const IconesArquivoUpado = styled.div`
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;
