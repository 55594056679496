import styled from "styled-components";

interface ExportButtonProps {
  variant: "primary" | "secondary";
  disabled?: boolean;
}

const ExportExcelContainer = styled.div<ExportButtonProps>`
  background-color: ${({ theme, variant }) =>
    variant === "primary" ? theme.colors.primary.main : theme.colors.white};
  padding: 0.4rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  border-radius: 0.5rem;
  color: ${({ theme, variant }) =>
    variant === "primary" ? theme.colors.white : theme.colors.primary.main};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  font-weight: bold;
  ${({ variant, theme }) =>
    variant === "secondary" &&
    `border: 1px solid ${theme.colors.primary.main}`};

  &:hover {
    color: ${({ theme, variant, disabled }) =>
      !disabled &&
      (variant === "primary"
        ? theme.colors.neutral.neutral10
        : theme.colors.primary.hover)};
    ${({ variant, theme, disabled }) =>
      !disabled &&
      variant === "secondary" &&
      `border: 1px solid ${theme.colors.primary.hover}`};
    transition: all ease-in-out 0.25s;
  }

  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  pointer-events: ${({ disabled }) =>
    disabled ? "none" : "auto"}; /* Bloqueia interação */
`;

const Container = styled.div`
  display: flex;
  justify-content: end;
  gap: 1.5rem;
  width: 100%;
  width: 100%;
`;

const ButtonToggleVisualizacao = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.neutral.neutral70};
  font-weight: bold;
  transition: color ease-in-out 0.2s;

  svg {
    color: ${({ theme }) => theme.colors.neutral.neutral70} !important;
    width: 1rem !important;
    height: 1rem;
    transition: color ease-in-out 0.2s;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.neutral.neutral50};

    svg {
      color: ${({ theme }) => theme.colors.neutral.neutral50} !important;
    }
  }

  @media (max-width: 1370px) {
    font-size: 0.75rem;
  }
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: ${({ theme }) => `4px solid ${theme.colors.primary.main}`};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export {
  ExportExcelContainer,
  ButtonToggleVisualizacao,
  Container,
  LoadingSpinner,
};
