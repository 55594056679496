import { Header } from "components/Header";
import { useEffect, useState } from "react";
import { useListar } from "../../hooks/useListar";
import { Filtros } from "./containers/Filtros";
import { ListarConsultas } from "./containers/ListarConsultas";
import { SolicitarConsultaV2 } from "./containers/SolicitarConsultaV2";
import { IOptions } from "components/DropdownSelect";
import { useFinanceiro } from "hooks/useFinanceiro";
import { IOptionsCheck } from "../../interfaces/IOptionsCheck";

export function Listar() {
  const { error, consultasSolicitada, listarConsultasSolicitada, loading, setLimit, setPage, totalRows,limit,filtro,setFiltro} = useListar();
  const [showConsultar, setShowConsultar] = useState<boolean>(false);
  const { configServicos, setConfigServicos, listarConfigServicosPorIdCliente } = useFinanceiro();
  const [optionsUf, setOptionsUf] = useState<IMultiselectOptions[]>([]);
  const [tipoDebitoEscolhido, setTipoDebitoEscolhido] = useState<Array<"IPVA" | "LICENCIAMENTO"> | null>(null);
  const [ufEscolhida, setUfEscolhida] = useState<string[]>([]);
  const [optionsCotaIpva, setOptionsCotaIpva] = useState<IOptions[]>([]);
  const [optionsTipoDebito, setOptionsTipoDebito] = useState<IOptionsCheck[]>([]);

  useEffect(() => {
    if (!error) return;
  }, [error]);

  useEffect(() => {
    listarConsultasSolicitada(filtro);
  }, [listarConsultasSolicitada,filtro]);

  function handleLimitChange(limit: number) {
    setLimit(limit);
  }

  function handlePageChange(page: number) {
    setPage(page);
  }

  async function handleConsultarDebitos() {
    setOptionsUf([]);
    setUfEscolhida([]);
    setTipoDebitoEscolhido(null);
    setOptionsCotaIpva([]);
    setConfigServicos([]);
    setShowConsultar(true);
    setOptionsTipoDebito([]);
  }

  return (
    <>
      <Header title="Consultar e Solicitar Pagamento de Débitos" redirect="/debito/consulta" />
      <SolicitarConsultaV2
        listarConsultasSolicitada={listarConsultasSolicitada}
        show={showConsultar}
        setShowConsultar={setShowConsultar}
        setOptionsUf={setOptionsUf}
        setUfEscolhida={setUfEscolhida}
        setTipoDebitoEscolhido={setTipoDebitoEscolhido}
        setOptionsCotaIpva={setOptionsCotaIpva}
        setConfigServicos={setConfigServicos}
        listarConfigServicosPorIdCliente={listarConfigServicosPorIdCliente}
        optionsUf={optionsUf}
        tipoDebitoEscolhido={tipoDebitoEscolhido}
        ufEscolhida={ufEscolhida}
        optionsCotaIpva={optionsCotaIpva}
        configServicos={configServicos}
        optionsTipoDebito={optionsTipoDebito}
        setOptionsTipoDebito={setOptionsTipoDebito}
      />
      <Filtros handleSubmitFiltros={setFiltro}
        handleConsultarDebitos={handleConsultarDebitos}
         />
      <ListarConsultas
        dados={consultasSolicitada}
        loading={loading}
        totalRows={totalRows}
        rowsPerPage={limit}
        onChangeRowsPerPage={handleLimitChange}
        onChangePage={handlePageChange}
      />
    </>
  );
}
