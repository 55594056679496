import { CompositeContainer } from "components/CompositeContainer";
import { useEffect, useState } from "react";
import { FaTrash, FaUpload } from "react-icons/fa";
import { useSolicitacaoUpload } from "../../hooks/useSolicitacaoUpload";
import { ArquivoUpado, ContainerArquivosUpados, ContainerUpload, ContainerUploadDragAndDrop, ContainerUploadIcone, HeaderArquivoUpado, IconesArquivoUpado } from "./style";
import { Uploads } from "./types/upload.type";
import { UploadStatus } from "./enum/uploadStatus.enum";

interface Props {
    tipoArquivo: string;
    renomearPara: string;
}

export function CompartimentoUpload({
    renomearPara,
    tipoArquivo,
}: Props) {
    const {
        uparDocumento,
    } = useSolicitacaoUpload();

    const [arquivos, setArquivos] = useState<Uploads[]>([]);

    const abrirDialogoUpload = () => {
        const componenteDeInput = document.getElementById('imgupload');
        if (componenteDeInput) {
            componenteDeInput.click();
        }
    };

    const validarTamanhoEExtensaoDoArquivo = (arquivo: File): boolean => {
        const extensaoArquivo = arquivo.name.slice(-3);

        if (extensaoArquivo !== 'pdf') {
            return false;
        }

        const tamanhoArquivoEmBytes = arquivo.size;

        if (tamanhoArquivoEmBytes > 5 * 1024 * 1024) {
            return false;
        }

        return true;
    }

    const [arquivosParaProcessar, setArquivosParaProcessar] = useState(false);

    const processarArquivos = (arquivos: FileList) => {
        if (!tipoArquivo || !renomearPara) return;

        const arrayDeArquivos = Array.from(arquivos);
        const mappedFiles: Uploads[] = arrayDeArquivos.map((arquivo) => ({
            arquivo: arquivo,
            status: validarTamanhoEExtensaoDoArquivo(arquivo) ? UploadStatus.PROCESSANDO : UploadStatus.INVALIDO,
            id: (Date.now() + Math.random()).toString(),
            porcentagemUpload: 0,
        }));

        setArquivos((prevArquivos) => [...prevArquivos, ...mappedFiles]);
        setArquivosParaProcessar(true);
    };

    const handleUploadDeArquivo = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            processarArquivos(event.target.files);
        }
    };

    const handleDrag = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const handleExcludeFileFromUpload = (id: string) => {
        const fileIndex = arquivos.findIndex((arquivo) => arquivo.id === id);
        const novosArquivos = [...arquivos];
        novosArquivos.splice(fileIndex, 1);
        setArquivos(novosArquivos);
    }

    const handleProgressoDeDownload = (progress: number, id: string) => {
        const arquviosCopiados = [...arquivos];

        const indexAlterado = arquviosCopiados.findIndex((arquivo) => arquivo.id === id);
        const arquivoEspecifico = arquviosCopiados[indexAlterado];
        arquivoEspecifico.porcentagemUpload = progress;
        arquviosCopiados[indexAlterado] = arquivoEspecifico;

        setArquivos(arquviosCopiados);
    }

    const handleTamanhoDaBarraDeProgresso = (id: string): number => {
        return Number(arquivos.find((arquivo) => arquivo.id === id)?.porcentagemUpload);
    }

    useEffect(() => {
        if (!arquivosParaProcessar) return;
    
        const processarArquivos = async () => {
            const arquivosSemUpload = arquivos.filter((arquivo) => 
                ![UploadStatus.CONCLUIDO, UploadStatus.INVALIDO].includes(arquivo.status) && arquivo.porcentagemUpload === 0
            );
    
            if (arquivosSemUpload.length === 0) {
                setArquivosParaProcessar(false);
                return;
            }
    
            const arquivosAtualizados = await Promise.all(
                arquivosSemUpload.map(async (arquivo) => {
                    try {
                        await uparDocumento({
                            arquivo: arquivo.arquivo,
                            renomearPara,
                            usuario: '',
                            handleProgressoDeDownload,
                            id: arquivo.id,
                        });
    
                        return {
                            ...arquivo,
                            status: UploadStatus.CONCLUIDO,
                            porcentagemUpload: 100,
                        };
                    } catch {
                        return {
                            ...arquivo,
                            status: UploadStatus.ERRO,
                            porcentagemUpload: 0,
                        };
                    }
                })
            );
    
            setArquivos((prev) =>
                prev.map((arquivo) =>
                    arquivosAtualizados.find((a) => a.id === arquivo.id) || arquivo
                )
            );
            setArquivosParaProcessar(false);
        };
    
        processarArquivos();
    }, [arquivosParaProcessar, arquivos, renomearPara]);

    const handleCorDoCardPorStatus = (status: string): string => {
        switch (status) {
            case UploadStatus.CONCLUIDO:
                return '#10AC85';
            case UploadStatus.ERRO:
                return '#E41324';
            case UploadStatus.INVALIDO:
                return '#E41324';
            case UploadStatus.PROCESSANDO:
                return 'orange';
            default:
                return 'gray';
        }
    }

    const handleDescricaoPorStatus = (status: string): string => {
        switch (status) {
            case UploadStatus.CONCLUIDO:
                return 'Concluído';
            case UploadStatus.ERRO:
                return 'Falha no upload';
            case UploadStatus.INVALIDO:
                return 'Arquivo inválido';
            case UploadStatus.PROCESSANDO:
                return '';
            default:
                return 'Status desconhecido';
        }
    }

    return (
        <CompositeContainer.Root>
            <CompositeContainer.Header>
                <CompositeContainer.Titulo text="Upload de Arquivos" />
            </CompositeContainer.Header>
            <CompositeContainer.Body>
                <ContainerUpload>
                    <ContainerUploadDragAndDrop
                        onDragOver={handleDrag}
                        onDragLeave={handleDrag}
                        onDragEnter={handleDrag}
                        onDrop={(event) => {
                            event.preventDefault();
                            const files = event.dataTransfer.files;
                            processarArquivos(files);
                        }}
                    >
                        <ContainerUploadIcone>
                            <FaUpload size={40} color='#009933' />
                        </ContainerUploadIcone>

                        <div>
                            <span style={{
                                color: '#009933',
                                cursor: 'pointer'
                            }}
                                onClick={abrirDialogoUpload}
                            >
                                Clique ou solte aqui
                            </span>
                            ⠀e faça o upload instantâneo
                            <input type="file" id="imgupload" style={{
                                display: 'none'
                            }}
                                onChange={handleUploadDeArquivo} />
                        </div>
                        <div>
                            Máx. 5MB | PDF
                        </div>
                    </ContainerUploadDragAndDrop>

                    <ContainerArquivosUpados>
                        {
                            arquivos.map((arquivo) => (
                                <ArquivoUpado>
                                    <HeaderArquivoUpado>
                                        <div>
                                            {arquivo.arquivo.name.length < 20 ? arquivo.arquivo.name : arquivo.arquivo.name.slice(0, 20).concat('...')}
                                        </div>
                                        {arquivo.status === UploadStatus.PROCESSANDO && (
                                            <div style={{
                                                width: '90%',
                                                height: '3px',
                                                backgroundColor: 'gray',
                                            }}>
                                                <div style={{
                                                    height: '100%',
                                                    backgroundColor: 'green',
                                                    width: `${handleTamanhoDaBarraDeProgresso(String(arquivo.id))}%`
                                                }} />
                                            </div>
                                        )}
                                        <div style={{
                                            color: `${handleCorDoCardPorStatus(arquivo.status)}`,
                                        }}>
                                            {handleDescricaoPorStatus(arquivo.status)}
                                        </div>
                                    </HeaderArquivoUpado>
                                    <IconesArquivoUpado
                                        onClick={() => {
                                            handleExcludeFileFromUpload(String(arquivo.id));
                                        }}
                                    >
                                        <FaTrash />
                                    </IconesArquivoUpado>
                                </ArquivoUpado>
                            ))
                        }
                    </ContainerArquivosUpados>
                </ContainerUpload>
            </CompositeContainer.Body>
        </CompositeContainer.Root>
    )
}