import { intlCurrencyFormat } from "_utils/Money";
import { IconeNadaConsta } from "../IconeNadaConsta";
import { IconePendencia } from "../IconePendencia";
import { Section } from "../Section";
import { Stronger } from "components/Stronger";
import { TextoClaro } from "components/TextoClaro";
import { IMultas } from "../DadosMultas";

export interface IDetalhesMultas {
	valor: number;
	descricaoArtigo: string;
	dataInfracao: string;
	horaInfracao: string;
	autoInfracao: string;
	dataVencimento: string;
	situacao: string;
	endereco: string;
	orgao: string;
	valorOriginal: number;
	valorComDesconto: number;
	valorJuro: number;
}

export interface DadosMultasProps {
	total: number;
	multas: IMultas | null;
}

export function DadosMultasNacional({ multas, total }: Readonly<DadosMultasProps>) {
	function handleNaoExisteDebitos(dados: any) {
		const temDebitos = Object.keys(dados).some((key) => Array.isArray(dados[key]) && dados[key].length > 0);

		return temDebitos;
	}

	const tiposMulta = [
		{ label: "Multas Detran", atributo: "multasDetran" },
		{ label: "Multas Cetesb", atributo: "multasCetesb" },
		{ label: "Multas municipais", atributo: "multasMunicipais" },
		{ label: "Multas RenaInf", atributo: "multasRenainf" },
		{ label: "Multas Dersa", atributo: "multasDersa" },
		{ label: "Multas DER", atributo: "multasDer" },
		{ label: "Multas PFR", atributo: "multasPfr" },
		{ label: "Multas (Outros)", atributo: "multas" },
	];

	return (
		<Section.Root>
			<Section.Header>
				<Section.Icone icone={total > 0 ? IconePendencia : IconeNadaConsta} />
				<Section.Titulo titulo="Multa" />
			</Section.Header>
			<Section.Body>
				<Section.Row>
					<Section.Col lg="7" md="7" sm="7">
						<span>Multas</span> <br />
						<Stronger>{total > 0 ? "Existe débito de Multas" : "Não existe débito de Multas"}</Stronger>
					</Section.Col>
					<Section.Col>
						<span>Valor total</span> <br />
						<Stronger>{total > 0 ? intlCurrencyFormat(total) : "-"}</Stronger>
					</Section.Col>
				</Section.Row>
				{multas && handleNaoExisteDebitos(multas) && (
					<>
						<Section.Row>
							<Section.Col lg="8" md="8" sm="8" style={{ margin: 0 }}>
								<TextoClaro>Descrição</TextoClaro>
							</Section.Col>
							<Section.Col lg="4" md="4" sm="4" style={{ margin: 0 }}>
								<TextoClaro>Valor</TextoClaro>
							</Section.Col>
						</Section.Row>
						{tiposMulta.map((item) => {
							return (
								<Section.Row
									style={{
										backgroundColor: "#E6E6E6",
										borderRadius: "0.75rem",
										marginTop: "0.5rem",
										padding: "0.5rem 0.25rem",
									}}
								>
									<Section.Col lg="8" md="8" sm="8" style={{ margin: 0 }}>
										{item.label}
									</Section.Col>
									<Section.Col lg="4" md="4" sm="4" style={{ margin: 0 }}>
										{intlCurrencyFormat(
											multas[item.atributo as keyof IMultas]
												? multas[item.atributo as keyof IMultas]
														.map((multa) => multa.valor + multa.valorJuro)
														.reduce((acc, cur) => acc + cur / 100, 0)
												: 0,
										)}
									</Section.Col>
								</Section.Row>
							);
						})}
					</>
				)}
			</Section.Body>
		</Section.Root>
	);
}
