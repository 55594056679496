import { LoadingCirculo } from "components/LoadingCirculo";
import { TipoDebitos } from "features/debito/consulta/enum/TipoDebitos";
import { useDebitosDetalhes } from "features/debito/consulta/hooks/useDetalhesDebitos";
import { useEffect, useState } from "react";
import { DebitoIpva } from "../DebitoIpva";
import { DebitoLicenciamento } from "../DebitoLicenciamento";
import {
  AtencaoContainer,
  DadosErrosBox,
  DadosErrosText,
  DadosErrosTitle,
  LoadingContainer,
} from "./style";
import { StatusSolicitacaoPagamentoEnumV2 } from "features/debito/consulta/enum/StatusSolicitacaoPagamento";

interface MensagemDebitosProps {
  idDebito: number;
  tipoDebito: any;
  exercicio?: number;
  temMulta?: number;
  descricao?: string;
  statusDebito?: string | number;
}

type TBodyDetalhes =
  | "ipva"
  | "errosSemMulta"
  | "licenciamento"
  | "descricaoMapeada"
  | "statusFalha"
  | null;

export function MensagemDebitos({
  idDebito,
  tipoDebito,
  exercicio,
  temMulta,
  descricao,
  statusDebito,
}: MensagemDebitosProps) {
  const { buscarDebitosDetalhes, debitosDetalhes, error, loading } =
    useDebitosDetalhes();
  const [body, setBody] = useState<TBodyDetalhes>(null);
  const mapeamentoDescricoes = [
    {
      descricao:
        "Solicitação de pagamento indisponível devido à instabilidade do órgão",
      mensagem:
        "Devido à indisponibilidade do órgão, o pagamento não pode ser solicitado. Por favor, realize o pagamento diretamente no site do Detran correspondente à UF de emplacamento.",
    },
    {
      descricao: "Não foi possível realizar a consulta",
      mensagem:
        "Não foi possível realizar a consulta. Entre em contato com o suporte ou tente novamente mais tarde.",
    },
  ];
  useEffect(() => {
    async function fetchData() {
      try {
        await buscarDebitosDetalhes(idDebito);

        if (mapeamentoDescricoes.some((item) => item.descricao === descricao)) {
          setBody("descricaoMapeada");
          return;
        }
        if (statusDebito == StatusSolicitacaoPagamentoEnumV2.Falha) {
          setBody("statusFalha");
        }
        if (TipoDebitos[tipoDebito] === "IPVA") {
          setBody("ipva");
        }
        if (error?.hasErro && !temMulta) {
          setBody("errosSemMulta");
        }
        if (TipoDebitos[tipoDebito] === "Licenciamento" && !error?.hasErro) {
          setBody("licenciamento");
        }
      } catch (error) {
        setBody(null);
      }
    }

    fetchData();
  }, [
    buscarDebitosDetalhes,
    idDebito,
    error?.hasErro,
    temMulta,
    tipoDebito,
    descricao,
  ]);

  function renderBody(body: TBodyDetalhes) {
    if (!body) {
      return <div></div>;
    }

    if (body === "ipva") {
      return <DebitoIpva exercicio={exercicio} />;
    }

    if (body === "licenciamento") {
      return (
        <DebitoLicenciamento
          licenciamentos={debitosDetalhes}
          temMulta={temMulta}
        />
      );
    }

    if (body === "descricaoMapeada") {
      return (
        <AtencaoContainer>
          <DadosErrosBox>
            <DadosErrosTitle>Atenção</DadosErrosTitle>
            <DadosErrosText>
              {
                mapeamentoDescricoes.filter(
                  (item) => item.descricao === descricao
                )[0].mensagem
              }
            </DadosErrosText>
          </DadosErrosBox>
        </AtencaoContainer>
      );
    }

    return (
      <AtencaoContainer>
        <DadosErrosBox>
          <DadosErrosTitle>Atenção</DadosErrosTitle>
          <DadosErrosText>{descricao}</DadosErrosText>
        </DadosErrosBox>
      </AtencaoContainer>
    );
  }

  return (
    <>
      {loading ? (
        <LoadingContainer>
          <LoadingCirculo mostrar={loading} />
        </LoadingContainer>
      ) : (
        renderBody(body)
      )}
    </>
  );
}
