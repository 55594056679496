import { Text, View } from "@react-pdf/renderer";
import { STYLES } from "../../style";
import { intlCurrencyFormat } from "_utils/Money";
import { RowMultasPDF } from "./containers/RowMultas";
import { IMultas } from "../../../DadosMultas";

interface Props {
  total: number;
  multas: IMultas | null;
}

export function DadosMultasPDFNacional({ multas, total }: Readonly<Props>) {
  function handleNaoExisteDebitos(dados: any) {
    const temDebitos = Object.keys(dados).some((key) => Array.isArray(dados[key]) && dados[key].length > 0);

    return temDebitos;
  }

  const tiposMulta = [
    { label: "Multas Detran", atributo: "multasDetran" },
    { label: "Multas Cetesb", atributo: "multasCetesb" },
    { label: "Multas municipais", atributo: "multasMunicipais" },
    { label: "Multas RenaInf", atributo: "multasRenainf" },
    { label: "Multas Dersa", atributo: "multasDersa" },
    { label: "Multas DER", atributo: "multasDer" },
    { label: "Multas PFR", atributo: "multasPfr" },
    { label: "Multas (Outros)", atributo: "multas" },
  ];

  return (
    <View style={STYLES.CONTAINER}>
      <Text style={[STYLES.TEXTO_CLARO, STYLES.FONT_SIZE_12]}>Multa</Text>
      <View style={STYLES.SECTION}>
        <View style={STYLES.SECTION_ROW}>
          <View style={STYLES.SECTION_COL_2}>
            <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Multas</Text>
            <Text style={STYLES.FONT_SIZE_09}>
              {handleNaoExisteDebitos(multas) ? "Existe débito de multas" : "Não existe débito de multas"}
            </Text>
          </View>
          <View style={STYLES.SECTION_COL_2}>
            <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Valor total</Text>
            <Text style={STYLES.FONT_SIZE_09}>{total > 0 ? intlCurrencyFormat(total) : "-"}</Text>
          </View>
        </View>
        {multas && handleNaoExisteDebitos(multas) && (
          <>
            <View style={STYLES.SECTION_ROW}>
              <View style={STYLES.SECTION_COL_3}>
                <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Descrição</Text>
              </View>
              <View style={STYLES.SECTION_COL_3}>
              </View>
              <View style={STYLES.SECTION_COL_3}>
                <Text style={[STYLES.TEXTO_QUASE_CLARO, STYLES.FONT_SIZE_09]}>Valor</Text>
              </View>
            </View>
            {tiposMulta.map((item) => {
              return (
                <RowMultasPDF
                  dadosMultas={multas[item.atributo as keyof IMultas] ? multas[item.atributo as keyof IMultas] : []}
                  textoRow={item.label}
                />
              )
            })}
          </>
        )}
      </View>
    </View>
  );
}

