import { formataDataISOParaBR } from "_utils/Data";
import { formatarDinheiro } from "_utils/Money";
import { PrimaryButton } from "components/Button/style";
import { Container } from "components/Container";
import { StatusSolicitacaoPagamento } from "components/StatusSolicitacaoPagamento";
import { Tooltip } from "components/Tooltip";
import { Table } from "containers/Table";
import { IColum } from "containers/Table/interfaces";
import { StatusSolicitacaoPagamentoEnumV2 } from "features/debito/consulta/enum/StatusSolicitacaoPagamento";
import { TipoDebitos } from "features/debito/consulta/enum/TipoDebitos";
import { IDetalhesConsulta } from "features/debito/consulta/interfaces/IDetalhesConsulta";
import { useEffect, useState } from "react";
import { ExpanderComponentProps } from "react-data-table-component";
import { FaDollarSign } from "react-icons/fa";
import { MdRefresh } from "react-icons/md";
import { utils, writeFileXLSX } from "xlsx";
import { IconeInfo } from "../IconeInfo";
import { MensagemDebitos } from "../MensagemDebitos";
import { Resumo } from "../Resumo";
import { TentativasConsultas } from "../TentativasConsultas";
import { IListarConsultasProps } from "./interface/IListarDetalhes";
import { ButtonHeader, TooltipContemt } from "./style";

export function makeStatusDebitosConsulta(statusDebito: string) {
  return <StatusSolicitacaoPagamento statusDebito={statusDebito} />;
}

export function ExpandedComponentConsulta({ data }: Readonly<ExpanderComponentProps<IDetalhesConsulta>>) {
  return (
    <MensagemDebitos
      idDebito={data.id}
      tipoDebito={data.debito}
      exercicio={data.exercicio}
      temMulta={data.temMulta}
      descricao={data.descricao}
      statusDebito={data.statusDebito}
    />
  );
}

export function makeTentativasConsulta(tentativas: number, status: number) {
  return <TentativasConsultas tentativas={tentativas} status={status} />;
}

export function ListarDetalhes({
  dados,
  loading,
  idConsulta,
  setRowExpandToggled,
  setSelectedRows,
  handleShow,
  disableReconsultar,
  solicitarTodos,
  rowToExpand,
  onChangePage,
  onChangeRowsPerPage,
  limit,
  totalRows,
  clearSelectedRows,
  onClickReconsultar,
  resumo,
  buscarDetalhesSemPaginacao,
}: Readonly<IListarConsultasProps>) {
  const [loadingExportar, setLoadingExportar] = useState<boolean>(false);

  const handleExport = async () => {
    setLoadingExportar(true);
    const dados = await buscarDetalhesSemPaginacao();

    const dadosPlanilha = dados.map((item: any) => ({
      Placa: item.placa.toUpperCase(),
      Renavam: item.renavam,
      UF: item.uf?.toUpperCase(),
      Debito: TipoDebitos[Number(item.debito)],
      "Cota IPVA": item.cota ? item.cota : "-",
      Vencimento: item.vencimento ? formataDataISOParaBR(item.vencimento.toString()) : "-",
      "Valor Total": item.total ? formatarDinheiro(item.total / 100) : "-",
      Observação:
        Number(item.statusDebito) === StatusSolicitacaoPagamentoEnumV2.Consultando
          ? "--"
          : item.mensagem ?? item.descricao !== "null"
          ? item.descricao
          : "--",
      Status: StatusSolicitacaoPagamentoEnumV2[item.statusDebito],
      "Tentativas Consulta": item.tentativas,
      "ID Recibo": item.idRecibo,
    }));

    const planilha = utils.json_to_sheet(dadosPlanilha);
    planilha["!cols"] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, planilha, "Detalhes");
    writeFileXLSX(workBook, "detalhes_solicitacao.xlsx");
    setLoadingExportar(false);
  };

  function makeIconeInfo(debito: any) {
    return (
      <IconeInfo
        statusDebito={debito.statusDebito}
        onClick={() => setRowExpandToggled(debito)}
        temMulta={debito.temMulta}
        idTipoDebito={debito.debito}
      />
    );
  }

  useEffect(() => {
    setSelectedRows([]);
  }, [dados]);

  const quantidadeCaracteresTextoLongo = 70;
  const widthStrategy = { md: 200, lg: 350 };
  const widthObservacao = `${Math.max(
    ...dados.map((item) =>
      item.descricao && item.descricao.length > quantidadeCaracteresTextoLongo ? widthStrategy.lg : widthStrategy.md
    )
  )}px`;

  const columns: IColum<IDetalhesConsulta>[] = [
    {
      name: <span>Id</span>,
      selector: ({ id }) => id,
      sortable: true,
      omit: true,
      sortField: "id",
    },
    {
      name: <span>Placa</span>,
      selector: ({ placa }) => placa.toUpperCase(),
      sortable: true,
      sortField: "placa",
    },

    {
      name: <span>Renavam</span>,
      selector: ({ renavam }) => renavam,
      width: "100px",
    },
    {
      name: <span>UF</span>,
      selector: ({ uf }) => uf.toUpperCase(),
      sortable: true,
      sortField: "uf",
      width: "80px",
    },
    {
      name: <span>Débito</span>,
      selector: ({ debito }) => TipoDebitos[debito] || "--",
      sortable: true,
      sortField: "debito",
      width: "115px",
    },
    {
      name: <span>Cota IPVA</span>,
      selector: ({ cota,cotaIpva }) => (!cota || (cotaIpva == 1 && cota == 8)? "-" : cota),
      sortable: true,
      sortField: "cota",
    },
    {
      name: <span>Vencimento</span>,
      selector: ({ vencimento }) => (vencimento ? formataDataISOParaBR(vencimento) : "--"),
      sortable: false,
      sortField: "vencimento",
      width: "125px",
    },
    {
      name: <span>Valor Total</span>,
      selector: ({ total }) => {
        const totalAtualizado = total / 100;
        return formatarDinheiro(totalAtualizado) || "--";
      },
      sortable: true,
      sortField: "total",
      width: "120px",
    },
    {
      name: <span>Observação</span>,
      selector: ({ descricao, mensagem, statusDebito }) =>
        Number(statusDebito) === StatusSolicitacaoPagamentoEnumV2.Consultando
          ? "--"
          : mensagem ?? descricao !== "null"
          ? descricao
          : "--",
      width: widthObservacao,
    },
    {
      name: <span>Info</span>,
      selector: (debito: IDetalhesConsulta) => makeIconeInfo(debito),
      width: "45px",
    },
    {
      name: <span>Status da consulta</span>,
      selector: ({ statusDebito }) => makeStatusDebitosConsulta(statusDebito),
      sortable: true,
      sortField: "status",
      width: "200px",
    },
    {
      name: <span>Tentativas consulta</span>,
      selector: ({ tentativas, statusDebito }) => makeTentativasConsulta(tentativas, Number(statusDebito)),
    },
    {
      name: <span>ID Recibo</span>,
      selector: ({ idRecibo }) => idRecibo || "--",
      sortable: true,
      sortField: "idRecibo",
      width: "115px",
    },
  ];

  const bottomTooltipReconsultar = 2.3;
  const rightTooltipReconsultar = -90;
  const widthTooltipReconsultar = 240;

  return (
    <Container titulo={`Detalhes Consulta ID - ${idConsulta}`}>
      <Resumo id={idConsulta} resumo={resumo} />
      <Table
        loadingExportar={loadingExportar}
        rowsPerPage={limit}
        exportExcel
        buttonExportVariant="secondary"
        columns={columns}
        data={dados}
        keyField="id"
        loading={loading}
        clearSelectedRows={clearSelectedRows}
        setRowsSelected={(row) => setSelectedRows(row)}
        selectableRows={true}
        toolsHeader={
          <ButtonHeader>
            <Tooltip
              bottom={bottomTooltipReconsultar}
              right={rightTooltipReconsultar}
              width={widthTooltipReconsultar}
              trigger={
                <PrimaryButton
                  onClick={onClickReconsultar}
                  outline
                  disabled={disableReconsultar}
                  buttonWidth="lg"
                  variante="primary"
                >
                  <MdRefresh style={{ transform: "scaleX(-1)" }} /> Consultar novamente
                </PrimaryButton>
              }
            >
              <TooltipContemt>
                <span>
                  Reconsultar novamente todos veículos com status de <strong>falha</strong>
                </span>
              </TooltipContemt>
            </Tooltip>
            <PrimaryButton onClick={solicitarTodos}>
              <FaDollarSign /> Solicitar pagamento
            </PrimaryButton>
          </ButtonHeader>
        }
        rowToExpand={rowToExpand}
        expandableRowsComponent={ExpandedComponentConsulta}
        pagination={true}
        paginationRowsPerPageOptions={[100, 200, 300]}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        totalRows={totalRows}
        handleExport={handleExport}
      />
    </Container>
  );
}
