import { yupResolver } from "@hookform/resolvers/yup";
import { FilterButton, PrimaryButton } from "components/Button/style";
import { Container } from "components/Container";
import { CustomInput } from "components/CustomInput";
import { DropdownSelect } from "components/DropdownSelect";
import { MaisFiltros } from "components/MaisFiltros";
import { Multiselect } from "components/Multiselect";
import { filtrosDetalhesRelatorioConsultaScheme } from "features/relatorios/consultas/validate/validator";
import { useMaisFiltros } from "hooks/useMaisFiltros";
import { useMaskInput } from "hooks/useMaskInput";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaFilter, FaSearch } from "react-icons/fa";
import { tipoDebitos } from "../../constants/tipoDebito";
import { IFiltro } from "./Interface/IFiltro";
import { ButtonContainer, DatePickerContainer } from "./style";
import { InternoLw } from "components/InternoLw";
import { useEmpresas } from "hooks/useEmpresas";
import { Cobrar } from "features/licenciamento/emissao/constants";
import ReactDatePicker from "react-datepicker";
import br from "date-fns/locale/pt-BR";
import { useState } from "react";
import { statusConsulta } from "../../constants/statusConsulta";

interface Props {
  handleSubmitFiltros: (data: IFiltro | null) => void;
  handleConsultarDebitos: () => void;
}

export function Filtros({ handleSubmitFiltros, handleConsultarDebitos }: Props) {
  const { handleAplicarMaisFiltros, handleNaoUsarMaisFiltros } = useMaisFiltros();
  const { mInputPlaca } = useMaskInput();
  const { mDataRangeInput } = useMaskInput();
  const { empresasOptions } = useEmpresas();
  const [dateRange, setDateRange] = useState<Array<Date | null>>([null, null]);
  const [startDate, endDate] = dateRange;

  const { handleSubmit, control, reset, register } = useForm<IFiltro>({
    resolver: yupResolver(filtrosDetalhesRelatorioConsultaScheme),
  });

  function handleDateRangeSelect(update: Array<Date | null>) {
    setDateRange(update);
  }

  function handleDataDigitada(value: string) {
    const dateRangePadrao = /^\d{2}\/\d{2}\/\d{4}-\d{2}\/\d{2}\/\d{4}$/;
    if (value && dateRangePadrao.test(value.replaceAll(" ", ""))) {
      const datas = value
        .replaceAll(" ", "")
        .split("-")
        .map((item: string) => {
          const partes = item.split("/");
          return new Date(Number(partes[2]), Number(partes[1]) - 1, Number(partes[0]));
        });

      handleDateRangeSelect(datas);
    }
  }

  return (
    <Container
      titulo="Filtros"
      headersComponets={
        <PrimaryButton onClick={handleConsultarDebitos}>
          Consultar débitos
          <FaSearch />
        </PrimaryButton>
      }
    >
      <Form
        noValidate
        onSubmit={handleSubmit((e) => {
          e.dataInicio = startDate;
          e.dataFim = endDate;
          handleSubmitFiltros(e);
        })}
      >
        <Row>
          <Col md={2}>
            <Form.Group>
              <Form.Label className="text-nowrap">ID Consulta</Form.Label>
              <Controller
                control={control}
                name="id"
                render={({ field: { onChange } }) => (
                  <CustomInput
                    type="text"
                    placeholder="Busque por ID"
                    onChange={(e: any) => {
                      onChange(mInputPlaca(e).replace(/-/g, ""));
                    }}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group>
              <Form.Label>Placa</Form.Label>
              <Controller
                control={control}
                name="placa"
                render={({ field: { onChange } }) => (
                  <CustomInput
                    type="text"
                    placeholder="Busque por placa"
                    onChange={(e: any) => {
                      onChange(mInputPlaca(e).replace(/-/g, ""));
                    }}
                  />
                )}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group>
              <Form.Label className="text-nowrap">Período de solicitação</Form.Label>
              <DatePickerContainer>
                <ReactDatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  locale={br}
                  placeholderText="Período de solicitação"
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange={true}
                  onChange={(update) => {
                    handleDateRangeSelect(update);
                  }}
                  onChangeRaw={(event: any) => {
                    if (!event.target.value) {
                      return;
                    }
                    handleDataDigitada(mDataRangeInput(event));
                  }}
                />
              </DatePickerContainer>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group>
              <Controller
                name="statusConsulta"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DropdownSelect
                    options={statusConsulta}
                    onSelect={onChange}
                    title="Status da consulta"
                    height="38px"
                    selectedValue={value ?? ""}
                  />
                )}
              />
            </Form.Group>
          </Col>

          <Col lg="2">
            <ButtonContainer>
              <FilterButton type="submit" disabled={false} loading={false}>
                <FaFilter /> Filtrar
              </FilterButton>
              <FilterButton
                type="reset"
                onClick={() =>
                  reset({
                    tipoDebito: [],
                  })
                }
                disabled={false}
                loading={false}
                outline={true}
              >
                Limpar
              </FilterButton>
            </ButtonContainer>
          </Col>
          <Col lg="2">
            <MaisFiltros
              cancelarOnClick={() => {
                handleNaoUsarMaisFiltros({
                  excluir: ["tipoDebito", "cotaIpva", "renavam", "idEmpresa", "cobranca"],
                  resetCallback: reset,
                });
              }}
              handleClickAplicarFiltros={handleAplicarMaisFiltros}
              limparTudoOnClick={() => {
                handleNaoUsarMaisFiltros({
                  excluir: ["tipoDebito", "cotaIpva", "renavam", "idEmpresa", "cobranca"],
                  resetCallback: reset,
                });
              }}
              widthModal="md"
            >
              <Form.Group>
                <Controller
                  name="tipoDebito"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Multiselect
                      options={tipoDebitos}
                      onChange={onChange}
                      title="Tipo de débito"
                      value={value ?? []}
                    />
                  )}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Cota IPVA</Form.Label>
                <Controller
                  name="cotaIpva"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DropdownSelect
                      required
                      selectedValue={value}
                      options={[
                        {
                          label: "Única",
                          value: 1,
                        },
                        {
                          label: "Parcelado",
                          value: 2,
                        },
                      ]}
                      onSelect={(e) => {
                        onChange(e);
                      }}
                    />
                  )}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Renavam</Form.Label>
                <Controller
                  control={control}
                  name="renavam"
                  render={({ field: { onChange, value } }) => (
                    <CustomInput type="text" placeholder="Busque por renavam" onChange={(e) => onChange(e)} value={value ?? ""} />
                  )}
                />
              </Form.Group>
              <InternoLw>
                <Form.Group>
                  <Controller
                    control={control}
                    name="idEmpresa"
                    render={({ field: { onChange, value } }) => (
                      <DropdownSelect
                        options={empresasOptions}
                        title="Empresa"
                        selectedValue={value}
                        onSelect={onChange}
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group>
                  <Controller
                    control={control}
                    name="cobranca"
                    render={({ field: { onChange, value } }) => (
                      <DropdownSelect
                        options={[
                          {
                            value: Cobrar.SIM,
                            label: "Cobrado do cliente",
                          },
                          {
                            value: Cobrar.NAO,
                            label: "Isento de cobrança",
                          },
                        ]}
                        title="Cobrança"
                        selectedValue={value}
                        onSelect={onChange}
                      />
                    )}
                  />
                </Form.Group>
              </InternoLw>
            </MaisFiltros>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
