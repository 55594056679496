import styled from "styled-components";

interface ITooltipContainer {
  transformX?: number;
}

export const TooltipContainer = styled.div<ITooltipContainer>`
  text-align: left;
  color: #FFFFFF;
  background-color: #333333;
  padding: 10px 20px;
  border-radius: 0.5rem;
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.15);
  transform: translateX(${({ transformX }) => `${transformX}` ?? '60'}%);
`;