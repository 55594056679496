import { Page } from "containers/Page";
import { CompartimentoUpload } from "./containers/CompartimentoUpload";
import { ConfiguracoesArquivos } from "./containers/ConfiguracoesArquivos";
import { useState } from "react";

export function UploadArquivos() {

  const [tipoArquivo, setTipoArquivo] = useState<string>('');
  const [renomearPara, setRenomearPara] = useState<string>('');

  return (
    <Page title="Upload de arquivos">
      <ConfiguracoesArquivos setRenomearPara={setRenomearPara} setTipoArquivo={setTipoArquivo}/>
      <CompartimentoUpload tipoArquivo={tipoArquivo} renomearPara={renomearPara} />
    </Page>
  );
}

