import { IHttpResponse } from "_services/api/interface/HttpResponse";
import { toastAtencao, toastErro } from "components/Toast";
import { useCount } from "containers/CountMoney/hook/useCount";
import { Page } from "containers/Page";
import { useExpandableRow } from "hooks/useExpandableRow";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Filtros } from "./Containers/Filtros";
import { IFiltro } from "./Containers/Filtros/Interface/IFiltro";
import { ListarDetalhes } from "./Containers/ListarDetalhes";
import { ModalConfirmacaoSolicitacao } from "./Containers/ModalConfirmacaoSolicitacao";
import { ModalInformacaoPagamento } from "./Containers/ModalInformacaoPagamento";
import { ModalReconsultarDebitos } from "./Containers/ModalReconsultarDebitos";
import { ModalSolicitarPagamento } from "./Containers/ModalSolicitarPagamento";
import { useDetalhes } from "./hook/useDetalhes";
import { useSolicitarPagamento } from "./hook/useSolicitarPagamento";
import { ModalReciboAdiantamento } from "./Containers/ModalReciboAdiantamento";
import { IDadosClienteReciboAdiantamento } from "./Containers/ListarDetalhes/interface/IDadosClienteReciboAdiantamento";
import { ModalSucessoSolicitarPagamento } from "./Containers/ModalSucessoSolicitarPagamento";
import { mergeArquivosParaPdf } from "_utils/pdf/MergeArquivosParaPdf";
import { FormaPgtoEnum } from "features/debito/pagamento/enum/FormaPgtoEnum";
import { IDadosBanco } from "../../interfaces/IDadosBanco";

interface ISolicitarPagamento {
  idConsulta: any;
  idsDebitos: any[];
  autorizadoPagamento: boolean;
  filtros?: IFiltro;
  idReciboAdiantamento: number;
  formaPgto: FormaPgtoEnum;
  codigoPix?: string;
  urlBoleto?: string;
  cliente: IDadosClienteReciboAdiantamento;
}

export function DetalheConsulta() {
  const { id: idConsulta } = useParams();

  const [dadosBanco, setDadosBanco] = useState<IDadosBanco>({ codigoBanco: "", nomeBanco: "", agencia: "", numeroConta: "" });
  const [loadingPdf, setLoadingPdf] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showConfirmacao, setShowConfirmacao] = useState<boolean>(false);
  const [idSolicitacao, setIdSolicitacao] = useState<number>(0);
  const [todos, setTodos] = useState<boolean>(false);
  const [dadosReconsultar, setDadosReconsultar] = useState<IHttpResponse | null>(null);
  const [disableReconsultar, setDisableReconsultar] = useState<boolean>(false);
  const [showModalInformacaoPagamento, setShowModalInformacaoPagamento] = useState<boolean>(false);
  const [formaPgto, setFormaPgto] = useState<FormaPgtoEnum | undefined>();
  const { setRowExpandToggled, rowToExpand } = useExpandableRow();
  const [showModalReciboAdiantamento, setShowModalReciboAdiantamento] = useState<boolean>(false);
  const [maisOpcoes, setMaisOpcoes] = useState<boolean>(false);
  const [idReciboAdiantamento, setIdReciboAdiantamento] = useState<number>(0);
  const [dadosClienteReciboAdiantamento, setDadosClienteReciboAdiantamento] = useState<IDadosClienteReciboAdiantamento>({
    endereco: "",
    numero: "",
    bairro: "",
    cidade: "",
    estado: "",
    cep: "",
    cnpj: "",
    razaoSocial: "",
    nomeFantasia: "",
    id: 0,
  })
  const [codigoPix, setCodigoPix] = useState<string>("");
  const [showModalSucessoSolicitacaoPagamento, setShowModalSucessoSolicitacaoPagamento] = useState<boolean>(false);
  const statusNotFound = 404;

  const {
    setSelectedRows,
    total,
    selectedRows,
    limparSelectedRows,
    setTotal,
    quantidadeDebitos,
    quantidadeDebitosForaDeSla,
    setQuantidadeDebitos,
    setQuantidadeDebitosForaDeSla,
    autorizadoPagamento,
    setAutorizadoPagamento,
    setDadosPorTipoDebito,
    dadosPorTipoDebito,
  } = useCount();

  const {
    solicitarPagamento,
    loadingPagamento,
    buscarDadosClienteEIdRecibo,
  } = useSolicitarPagamento();

  const {
    dados,
    loading,
    setFiltro,
    buscarDetalhes,
    setLimit,
    limit,
    setPage,
    filtro,
    page,
    totalRows,
    buscarTodosDebitosConsulta,
    reconsultarDebitos,
    buscarResumoConsultaV2,
    resumo,
    idCliente,
    listarDetalhes,
  } = useDetalhes();

  async function buscarDetalhesSemPaginacao() {
    const paginacao = false;
    const { data, hasErro } = await listarDetalhes(idConsulta!, paginacao);

    if (hasErro || data.status == statusNotFound) {
      toastErro(data.message);
      return;
    }
    return data;
  }

  const handleClose = useCallback(() => {
    if (todos) {
      limparSelectedRows();
      setTodos(false);
    }
    setShow(false);
  }, [limparSelectedRows, setTodos, setShow, todos]);

  const buscarValorDebitos = useCallback(async () => {
    const { data, hasErro } = await buscarTodosDebitosConsulta(idConsulta!);

    if (hasErro || data.status == statusNotFound) {
      toastErro(data.message);
      handleClose();
      return;
    }
    setDadosPorTipoDebito([
      { descricao: "Pagamento IPVA", quantidade: Number(data.dadosPorTipoDebito.IPVA.quantidade), valor: Number(data.dadosPorTipoDebito.IPVA.valor) },
      { descricao: "Pagamento Licenciamento", quantidade: Number(data.dadosPorTipoDebito.Licenciamento.quantidade), valor: Number(data.dadosPorTipoDebito.Licenciamento.valor) },
      { descricao: "Pagamento Multa", quantidade: Number(data.dadosPorTipoDebito.Multa.quantidade), valor: Number(data.dadosPorTipoDebito.Multa.valor) },
      { descricao: "Pagamento DPVAT", quantidade: Number(data.dadosPorTipoDebito.DPVAT.quantidade), valor: Number(data.dadosPorTipoDebito.DPVAT.valor) },
    ]);
    setQuantidadeDebitos(data.totalDebitos);
    setTotal(data.valorTotal);
    setQuantidadeDebitosForaDeSla(data.totalForaDeSla);
    setAutorizadoPagamento(data.totalForaDeSla === 0);
    setIdsDebitos(data.idsDebitos);
  }, [filtro]);

  const [idsDebitos, setIdsDebitos] = useState<number[]>([]);

  useEffect(() => {
    buscarDetalhes(idConsulta);
    buscarResumoConsultaV2(idConsulta)
  }, [buscarDetalhes, filtro, idConsulta, page, limit]);

  const [clearRows, setClearRows] = useState<boolean>(false);

  useEffect(() => {
    setDisableReconsultar(false);
    if (selectedRows.length > 0) {
      setDisableReconsultar(true);
    }
  }, [selectedRows]);

  const handleShow = () => setShow(true);

  const handleCloseModalConfirmacao = () => {
    setShowConfirmacao(false);
  };

  const solicitarTodos = async () => {
    limparSelectedRows();
    setTodos(true);

    handleShow();
  };

  function handleShowModalInformacaoPagamento() {
    setShowModalInformacaoPagamento(true);
  }

  async function handleSolicitarPagamento() {
    if (selectedRows.length > 0) {
      handleShowModalInformacaoPagamento();
      return;
    }

    await solicitarTodos();
  }

  const getLinhas = (linhas: any[]) => {
    setSelectedRows(linhas);
    handleIdsDebitos(linhas);
  };

  function handleIdsDebitos(debitos: any[]) {
    setIdsDebitos(debitos.map((item) => item.id));
  }

  const getFiltro = (filtros: IFiltro) => {
    setFiltro(filtros);
  };

  const solicitaPagamentos = async () => {
    if (!formaPgto) {
      toastAtencao("É necessário escolher uma forma pagamento para prosseguir");
      return;
    }

    const dadosSolicitarPagamento: ISolicitarPagamento = {
      idConsulta: idConsulta!,
      idsDebitos,
      autorizadoPagamento,
      filtros: filtro,
      idReciboAdiantamento,
      formaPgto,
      cliente: dadosClienteReciboAdiantamento,
    }

    const { data, hasErro } = await solicitarPagamento(dadosSolicitarPagamento);
    if (hasErro) {
      handleCloseModalReciboAdiantamento();
      handleClearTable();
      return;
    }

    setIdSolicitacao(data.id);
    setCodigoPix(data.codigoPix);
    buscarDetalhes(idConsulta);

    const downloadElement = document.getElementById(`downloadPDF_${idReciboAdiantamento}`);

    try {
      setLoadingPdf(true);
      if (downloadElement && downloadElement instanceof HTMLAnchorElement) {
        const itensPdf = [downloadElement.href];
        if (formaPgto === FormaPgtoEnum.PIXBOLETO) {
          itensPdf.push(data.linkBoleto);
        }
        const blobPdf = await mergeArquivosParaPdf(itensPdf)
        const url = URL.createObjectURL(blobPdf);
        setShowModalSucessoSolicitacaoPagamento(true);
        window.open(url)
      } else {
        throw new Error("PDF não encontrado.")
      }

    } catch (e) {
      toastErro("Não foi possível baixar o arquivo. Por favor, entre em contato com o suporte.")
    }

    setLoadingPdf(false);
    handleCloseModalReciboAdiantamento();
    handleClearTable();
  };

  const handleLimitChange = (limit: number) => {
    setLimit(limit);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  function handleClearTable() {
    setClearRows(true);
  }

  function handleCheck(checked: boolean) {
    setAutorizadoPagamento(checked);
  }

  async function handleReconsultarDebitos() {
    const response = await reconsultarDebitos(idConsulta);
    setDadosReconsultar(response);
  }

  const onConfirmModalSolicitarPagamento = async () => {
    if (quantidadeDebitosForaDeSla > 0 && !autorizadoPagamento) {
      toastAtencao("É necessário autorizar o pagamento para prosseguir");
      return;
    }

    if (!idCliente) {
      toastErro("Dados o cliente não encontrados");
      return;
    }
    const { data, hasErro } = await buscarDadosClienteEIdRecibo(idCliente);

    if (hasErro) {
      toastErro(`${data.message}`);
      handleClose();
      return;
    }

    if (
      !data.cliente.endereco ||
      !data.cliente.numero ||
      !data.cliente.bairro ||
      !data.cliente.cidade ||
      !data.cliente.estado ||
      !data.cliente.cep ||
      !data.cliente.cnpj ||
      !data.cliente.razaoSocial ||
      !data.cliente.nomeFantasia
    ) {
      toastErro("Não foi possível obter todos os dados necessários para o pagamento");
      handleClose();
      return;
    }

    setDadosClienteReciboAdiantamento({
      endereco: data.cliente.endereco,
      numero: data.cliente.numero,
      bairro: data.cliente.bairro,
      cidade: data.cliente.cidade,
      estado: data.cliente.estado,
      cep: data.cliente.cep,
      cnpj: data.cliente.cnpj,
      razaoSocial: data.cliente.razaoSocial,
      nomeFantasia: data.cliente.nomeFantasia,
      id: idCliente,
    })
    setIdReciboAdiantamento(data.idReciboAdiantamento)
    setShowModalReciboAdiantamento(true);
    setShow(false);
    setDadosBanco(data.banco);
  }

  const handleCloseModalReciboAdiantamento = () => {
    setFormaPgto(undefined);
    setShowModalReciboAdiantamento(false);
    setMaisOpcoes(false);
    handleClose();
  }

  return (
    <Page title="Detalhes Consultar Débitos" redirectDoIcone="/debito/consulta">
      <Filtros handleSubmitFiltros={getFiltro} />
      <ModalSolicitarPagamento
        show={show}
        handleClose={handleClose}
        total={total}
        loading={loading}
        todos={todos}
        onConfirm={onConfirmModalSolicitarPagamento}
        loadingPagamento={loadingPagamento}
        idConsulta={idConsulta!}
        buscarValorTodosDebitos={buscarValorDebitos}
        setIdsDebitos={handleIdsDebitos}
        quantidadeDebitos={quantidadeDebitos}
        quantidadeDebitosForaDeSla={quantidadeDebitosForaDeSla}
        handleCheck={handleCheck}
      />
      <ListarDetalhes
        buscarDetalhesSemPaginacao={buscarDetalhesSemPaginacao}
        limit={limit}
        dados={dados}
        loading={loading}
        idConsulta={idConsulta}
        setRowExpandToggled={setRowExpandToggled}
        setSelectedRows={getLinhas}
        disableReconsultar={disableReconsultar}
        onClickReconsultar={handleReconsultarDebitos}
        handleShow={handleShow}
        rowToExpand={rowToExpand}
        solicitarTodos={handleSolicitarPagamento}
        onChangeRowsPerPage={handleLimitChange}
        onChangePage={handlePageChange}
        totalRows={totalRows}
        clearSelectedRows={clearRows}
        filtro={filtro}
        resumo={resumo}
      />
      <ModalReconsultarDebitos
        handleClose={() => {
          setDadosReconsultar(null);
          buscarDetalhes(idConsulta);
        }}
        dados={dadosReconsultar}
      />
      <ModalConfirmacaoSolicitacao
        idSolicitacao={idSolicitacao}
        handleClose={handleCloseModalConfirmacao}
        show={showConfirmacao}
      />
      <ModalInformacaoPagamento
        show={showModalInformacaoPagamento}
        handleClose={() => setShowModalInformacaoPagamento(false)}
        onConfirm={() => {
          setShowModalInformacaoPagamento(false);
          handleShow();
        }}
      />
      <ModalReciboAdiantamento
        show={showModalReciboAdiantamento}
        handleClose={handleCloseModalReciboAdiantamento}
        solicitarPagamento={solicitaPagamentos}
        loading={loadingPagamento || loadingPdf}
        setFormaPgto={setFormaPgto}
        formaPgto={formaPgto}
        dados={{
          dadosPorTipoDebito,
          valorTotal: total,
          idReciboAdiantamento,
          dadosClienteReciboAdiantamento,
          dadosBanco
        }}
        maisOpcoes={maisOpcoes}
        setMaisOpcoes={setMaisOpcoes}
      />
      <ModalSucessoSolicitarPagamento
        show={showModalSucessoSolicitacaoPagamento}
        handleClose={() => {
          setIdSolicitacao(0);
          setCodigoPix("");
          setShowModalSucessoSolicitacaoPagamento(false);
        }}
        codigoPix={codigoPix}
        id={idSolicitacao}
      ></ModalSucessoSolicitarPagamento>
    </Page>
  );
}
