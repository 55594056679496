import { yupResolver } from "@hookform/resolvers/yup";
import { CompositeContainer } from "components/CompositeContainer";
import { ModalCustom } from "components/ModalCustom";
import { toastErro, toastSucesso } from "components/Toast";
import { BodyOption } from "features/arquivos/interfaces";
import { useSolicitacaoConsulta } from "features/debito/consulta/hooks/useSolicitacaoConsulta";
import { IConsultaSolicitada } from "features/debito/consulta/interfaces/IConsultaSolicitada";
import { consultarVeiculoUnitarioSchema } from "features/debito/consulta/validate/validator";
import { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BodyLote } from "./BodyLote";
import { BodyUnitario } from "./BodyUnitario";
import { CheckBody } from "./CheckBody";
import { ModalSolicitacaoEnviada } from "./ModalSolicitacaoEnviada";
import { useSocket } from "hooks/useSocket";
import { UploadContext } from "contexts/UploadContext";
import { IOptions } from "components/DropdownSelect";
import { ServicosPortalInterno } from "features/debito/consulta/enum/ServicosPortalInterno";
import { ModalValorConsultarDebitos } from "../ModalValorConsultarDebitos";
import { OpcaoCota } from "features/debito/consulta/enum/OpcaoCota";
import { ConfigServicosIdCliente } from "interfaces/ConfigServicosIdCliente";
import { IOptionsCheck } from "features/debito/consulta/interfaces/IOptionsCheck";
interface SolicitarConsultaProps {
  listarConsultasSolicitada: () => void;
  show: boolean;
  setShowConsultar(show: boolean): void;
  setOptionsUf(options: IMultiselectOptions[]): void;
  setUfEscolhida(array: string[]): void;
  setTipoDebitoEscolhido(tipo: Array<"IPVA" | "LICENCIAMENTO"> | null): void;
  setOptionsCotaIpva(options: IOptions[]): void;
  setConfigServicos(config: ConfigServicosIdCliente[]): void;
  listarConfigServicosPorIdCliente(idCliente: string | number): void;
  setOptionsTipoDebito(options: IOptionsCheck[]): void;
  optionsUf: IMultiselectOptions[];
  tipoDebitoEscolhido: Array<"IPVA" | "LICENCIAMENTO"> | null;
  ufEscolhida: string[];
  optionsCotaIpva: IOptions[];
  configServicos: ConfigServicosIdCliente[];
  optionsTipoDebito: IOptionsCheck[];
}

export function SolicitarConsultaV2({
  listarConsultasSolicitada,
  show,
  setShowConsultar,
  setOptionsUf,
  setUfEscolhida,
  setTipoDebitoEscolhido,
  setOptionsCotaIpva,
  setConfigServicos,
  listarConfigServicosPorIdCliente,
  setOptionsTipoDebito,
  optionsUf,
  tipoDebitoEscolhido,
  ufEscolhida,
  optionsCotaIpva,
  configServicos,
  optionsTipoDebito,
}: SolicitarConsultaProps) {
  const idsServicoConsultaDebitosIPVA = [
    ServicosPortalInterno.CONSULTA_IPVA_COTA_PARCELADA,
    ServicosPortalInterno.CONSULTA_IPVA_COTA_UNICA,
  ];
  const idsServicoConsultaDebitosLicenciamento = [
    ServicosPortalInterno.CONSULTA_LICENCIAMENTO,
  ];
  const [body, setBody] = useState<BodyOption>("lote");
  const [modalSize, setModalSize] = useState("lg");
  const { errorFile, error } = useSolicitacaoConsulta();
  const { socket } = useSocket();
  const [opcaoCotaEsolhida, setOpcaoCotaEscolhida] = useState<OpcaoCota.PARCELADA | OpcaoCota.UNICA | null>(null);
  const [onConfirmModalValor, setOnConfirmModalValor] = useState<() => void>(() => { });
  const [showModalValorConsulta, setShowModalValorConsulta] = useState<boolean>(false);
  const [qtdLinhas, setQtdLinhas] = useState<number>(0);
  const [valorConsulta, setValorConsulta] = useState<number>(0);
  const [loadingModalValorConsulta, setLoadingModalValorConsulta] = useState<boolean>(false);
  const [dadosPlanilha, setDadosPlanilha] = useState<any[]>([]);

  const { modalAberto } = useContext(UploadContext);

  useEffect(() => {
    if (modalAberto) {
      handleClose();
    }
  }, [modalAberto]);

  const { reset } = useForm<IConsultaSolicitada>({
    resolver: yupResolver(consultarVeiculoUnitarioSchema),
  });

  useEffect(() => {
    const sizeStrategy = {
      lote: "xl",
      individual: "xl",
    };

    setModalSize(sizeStrategy[body]);
  }, [body]);

  function handleClose() {
    setShowConsultar(false);
    setBody("lote");
  }

  const close = useCallback(() => {
    setShowConsultar(false);
    setBody("lote");
  }, []);

  useEffect(() => {
    if (!error) {
      return;
    }

    if (!error.hasErro) {
      toastSucesso("Solicitação realizada");
      reset();
      listarConsultasSolicitada();
    }
    if (error.hasErro) {
      const { data } = error;
      toastErro(`${data.message}`);
    }
  }, [error, listarConsultasSolicitada, reset]);

  useEffect(() => {
    if (!errorFile) return;
    if (!errorFile.hasErro) {
      listarConsultasSolicitada();
    }
  }, [errorFile, listarConsultasSolicitada]);

  function handleCheckBody(event: ChangeEvent<HTMLInputElement>) {
    reset();
    setOptionsUf([]);
    setUfEscolhida([]);
    setTipoDebitoEscolhido(null);
    setOptionsCotaIpva([]);
    setConfigServicos([]);
    setOpcaoCotaEscolhida(null);
    setBody(event.target.value as BodyOption);
  }

  function handleChangeEmpresa(idEmpresa: number | string) {
    listarConfigServicosPorIdCliente(idEmpresa)
  }

  useEffect(() => {
    if (configServicos && configServicos.length) {
      const options: IOptionsCheck[] = [];
      if (configServicos.some((servico) => [
        ServicosPortalInterno.CONSULTA_IPVA_COTA_PARCELADA,
        ServicosPortalInterno.CONSULTA_IPVA_COTA_UNICA
      ].includes(servico.idServico))) {
        options.push({
          value: "IPVA",
          label: "IPVA",
          checked: false,
        })
      }

      if (configServicos.some((servico) => servico.idServico == ServicosPortalInterno.CONSULTA_LICENCIAMENTO)) {
        options.push({
          value: "LICENCIAMENTO",
          label: "Licenciamento",
          checked: false,
        })
      }

      setOptionsTipoDebito(options);
      return;
    }
    setOptionsTipoDebito([]);
  }, [configServicos]);

  useEffect(() => {
    if (tipoDebitoEscolhido && !tipoDebitoEscolhido.includes("IPVA")) {
      setOptionsCotaIpva([]);
      return
    }

    if (configServicos && configServicos.length && tipoDebitoEscolhido) {
      const options: IOptions[] = [];
      if (configServicos.some(item => item.idServico == ServicosPortalInterno.CONSULTA_IPVA_COTA_PARCELADA)) {
        options.unshift({
          label: "Parcelado",
          value: OpcaoCota.PARCELADA,
        })
      }
      if (configServicos.some(item => item.idServico == ServicosPortalInterno.CONSULTA_IPVA_COTA_UNICA)) {
        options.unshift({
          label: "Única",
          value: OpcaoCota.UNICA,
        })
      }
      setOptionsCotaIpva(options);
      return;
    }
    setOptionsCotaIpva([]);
  }, [configServicos, tipoDebitoEscolhido]);

  useEffect(() => {
    if (configServicos && configServicos.length && tipoDebitoEscolhido) {
      if (tipoDebitoEscolhido.includes("IPVA") && !opcaoCotaEsolhida) {
        setOptionsUf([]);
        return
      }

      const options = configServicos
        .filter((item) => {
          if (!item.uf) {
            return false;
          }

          if (
            item.idServico == ServicosPortalInterno.CONSULTA_IPVA_COTA_PARCELADA
            && tipoDebitoEscolhido.includes("IPVA")
            && opcaoCotaEsolhida == OpcaoCota.PARCELADA
            && (
              !tipoDebitoEscolhido.includes("LICENCIAMENTO")
              || configServicos.some(servico => item.uf === servico.uf && idsServicoConsultaDebitosLicenciamento.includes(servico.idServico))
            )
          ) {
            return true;
          }

          if (
            item.idServico == ServicosPortalInterno.CONSULTA_IPVA_COTA_UNICA
            && tipoDebitoEscolhido.includes("IPVA")
            && opcaoCotaEsolhida == OpcaoCota.UNICA
            && (
              !tipoDebitoEscolhido.includes("LICENCIAMENTO")
              || configServicos.some(servico => item.uf === servico.uf && idsServicoConsultaDebitosLicenciamento.includes(servico.idServico))
            )
          ) {
            return true;
          }

          if (
            item.idServico == ServicosPortalInterno.CONSULTA_LICENCIAMENTO
            && tipoDebitoEscolhido.includes("LICENCIAMENTO")
            && (
              !tipoDebitoEscolhido.includes("IPVA")
              || configServicos.some(servico => item.uf === servico.uf && idsServicoConsultaDebitosIPVA.includes(servico.idServico))
            )
          ) {
            return true;
          }

          return false;

        }).map(item => item.uf).filter((value, index, self) => {
          return self.indexOf(value) === index;
        })
        .map(uf => { return { value: uf, label: uf, checked: false } });

      if (options.length > 1 && body === "lote") {
        options.unshift({ value: "todas", label: "Todas", checked: false })
      }
      setOptionsUf(options)
      return;
    }
    setOptionsUf([]);
  }, [configServicos, tipoDebitoEscolhido, opcaoCotaEsolhida, body]);

  useEffect(() => {

    if (tipoDebitoEscolhido && configServicos && configServicos.length && ufEscolhida && dadosPlanilha && dadosPlanilha.length) {
      let custoUnitario = 0;
      if (tipoDebitoEscolhido.includes("IPVA") && opcaoCotaEsolhida && opcaoCotaEsolhida == OpcaoCota.PARCELADA) {
        configServicos.filter(
          item => item.idServico == ServicosPortalInterno.CONSULTA_IPVA_COTA_PARCELADA &&
            ufEscolhida.includes(item.uf)
        ).forEach(item => {
          custoUnitario += Number(item.valor) * dadosPlanilha.filter((dado) => dado.UF === item.uf).length
        })
      }

      if (tipoDebitoEscolhido.includes("IPVA") && opcaoCotaEsolhida && opcaoCotaEsolhida == OpcaoCota.UNICA) {
        configServicos.filter(
          item => item.idServico == ServicosPortalInterno.CONSULTA_IPVA_COTA_UNICA &&
            ufEscolhida.includes(item.uf)
        ).forEach(item => {
          custoUnitario += Number(item.valor) * dadosPlanilha.filter((dado) => dado.UF === item.uf).length
        })
      }

      if (tipoDebitoEscolhido.includes("LICENCIAMENTO")) {
        configServicos.filter(
          item => item.idServico == ServicosPortalInterno.CONSULTA_LICENCIAMENTO &&
            ufEscolhida.includes(item.uf)
        ).forEach(item => {
          custoUnitario += Number(item.valor) * dadosPlanilha.filter((dado) => dado.UF === item.uf).length
        })
      }

      setValorConsulta(custoUnitario)
      return;
    }
  }, [configServicos, opcaoCotaEsolhida, tipoDebitoEscolhido, ufEscolhida, dadosPlanilha]);

  const bodyStrategy: { [key: string]: React.JSX.Element } = {
    lote: <BodyLote
      socket={socket}
      close={close}
      handleChangeEmpresa={handleChangeEmpresa}
      optionsUf={optionsUf}
      setTipoDebitoEscolhido={setTipoDebitoEscolhido}
      setUfEscolhida={setUfEscolhida}
      optionsCotaIpva={optionsCotaIpva}
      setOnConfirmModalValor={setOnConfirmModalValor}
      setShowModalValor={setShowModalValorConsulta}
      setLoadingModalValor={setLoadingModalValorConsulta}
      setValorConsulta={setValorConsulta}
      setQtdLinhas={setQtdLinhas}
      setOpcaoCotaEscolhida={setOpcaoCotaEscolhida}
      optionsTipoDebito={optionsTipoDebito}
      listarConfigServicos={listarConfigServicosPorIdCliente}
      setDadosPlanilha={setDadosPlanilha}
      setShowConsultar={setShowConsultar}
    />,
    individual: <BodyUnitario
      listarConsultasSolicitada={listarConsultasSolicitada}
      close={close} handleChangeEmpresa={handleChangeEmpresa}
      optionsUf={optionsUf}
      setTipoDebitoEscolhido={setTipoDebitoEscolhido}
      setUfEscolhida={setUfEscolhida}
      optionsCotaIpva={optionsCotaIpva}
      setOpcaoCotaEscolhida={setOpcaoCotaEscolhida}
      setOnConfirmModalValor={setOnConfirmModalValor}
      setShowModalValor={setShowModalValorConsulta}
      setLoadingModalValor={setLoadingModalValorConsulta}
      setValorConsulta={setValorConsulta}
      setQtdLinhas={setQtdLinhas}
      optionsTipoDebito={optionsTipoDebito}
      listarConfigServicos={listarConfigServicosPorIdCliente}
    />,
  };

  const closeModalValorConsulta = () => {
    setShowModalValorConsulta(false);
    setOnConfirmModalValor(() => { });
    setQtdLinhas(0);
    setValorConsulta(0);
  }

  return (
    <>
      <ModalCustom show={show} handleClose={handleClose} title="Consultar débitos" centered size={modalSize as any}>
        <CompositeContainer.Body>
          <CheckBody handleCheckOption={handleCheckBody} />
          {bodyStrategy[body]}
        </CompositeContainer.Body>
      </ModalCustom>
      <ModalValorConsultarDebitos
        show={showModalValorConsulta}
        loading={loadingModalValorConsulta}
        qtdLinhas={qtdLinhas}
        valorConsulta={valorConsulta}
        handleClose={closeModalValorConsulta}
        onConfirm={onConfirmModalValor}
      />
    </>
  );
}
