import { IBodySolicitarUpload } from "features/arquivos/interfaces";
import { useRequest } from "hooks/useRequest";
import { useCallback } from "react";

export function useSolicitacaoUpload() {

    const { postWithProgress } = useRequest("consulta");

    const uparDocumento = useCallback(async ({ arquivo, renomearPara, usuario, handleProgressoDeDownload, id }: IBodySolicitarUpload) => {
        const formData = new FormData();

        formData.append("arquivo", arquivo);
        formData.append("metadata", JSON.stringify({ renomearPara, usuario }));

        const { data, hasErro } = await postWithProgress("/licenciamento/consultas/upload", formData, {
            'Content-Type': 'multipart/form-data'
        }, false, handleProgressoDeDownload, id)


        if (hasErro) {
            return;
        }

        return data;
    }, [postWithProgress]);


    return {
        uparDocumento,
    }
}