import { InfoTooltip } from "components/InfoTooltip";
import { toastSucesso } from "components/Toast";
import { Tooltip } from "components/Tooltip";
import { CSSProperties, useState } from "react";
import { FaCopy } from "react-icons/fa";

interface IIconeCopiar {
    texto: string;
    mensagem: string;
    maxWidthCell?: number;
    maxWidthTexto?: number;
    tooltipBottom?: number;
    tooltipWidth?: number;
    tooltipRight?: number;
}

export function IconeCopiar({
    texto,
    mensagem,
    maxWidthCell = 140,
    maxWidthTexto = 180,
    tooltipBottom = -1,
    tooltipWidth = 190,
    tooltipRight = -157,
}: IIconeCopiar) {

    const styleHovered: CSSProperties = {
        maxWidth: `${maxWidthCell}px`,
        marginRight: '5px',
        position: 'relative',
    }

    const styleNormal: CSSProperties = {
        ...styleHovered,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    }

    const onClick = () => {
        navigator.clipboard.writeText(texto);
        toastSucesso("Copiado para área de transferência!");
    }

    const [style, setStyle] = useState<CSSProperties>(styleNormal);

    return (
        <span style={{ display: 'inline-flex', alignItems: 'center', maxWidth: `${maxWidthTexto}px` }}>
            <div
                style={style}
                onMouseEnter={() => setStyle(styleHovered)}
                onMouseLeave={() => setStyle(styleNormal)}
            >
                {texto}
            </div>
            <div>
                <Tooltip positionRelative={false} right={tooltipRight} bottom={tooltipBottom} width={tooltipWidth} trigger={<FaCopy onClick={onClick} style={{ cursor: "pointer" }} />}>
                    <InfoTooltip mensagem={mensagem} transformeX={0} />
                </Tooltip>
            </div>
        </span>
    );
}
